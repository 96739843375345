import { defaultedData } from '@/utils/helpers';
import { isNil, omitBy } from 'lodash-es';
import { IBaseData } from '../utils/base-data';
import { MessageSchema } from './message.schema';

export type ReactionType = 'like' | 'love' | 'haha' | 'wow' | 'sad' | 'please' | 'thanks' | 'eyes' | 'check' | 'ex';

export interface IReactionData {
  id: string;
  user: string;
  reaction: ReactionType;
  createdAt: Date;
}

export interface IMessageData extends IBaseData {
  /**
   * Contenido del mensaje.
   */
  content: string;

  /**
   * Tipo del mensaje.
   */
  contentType: 'text' | 'action' | 'notification' | string;

  /**
   * ID del usuario que envía el mensaje.
   */
  senderId: string;

  /**
   * ID del chat al que pertenece el mensaje.
   */
  chatId: string;

  /**
   * Indica si el mensaje ya fue visto por al menos uno de los receptores del mensaje.
   *
   * @deprecated Use `.seenBy[<USER_ID>]` instead.
   */
  seen: boolean;

  /**
   * Mapa con los usuarios que han visto el mensaje, indexados por su ID y con la fecha en que lo leyó.
   */
  seenBy: Record<string, Date>;

  // parentId: string | null;

  /**
   * Referencia al archivo relacionado con el mensaje.
   */
  mediaId: string | null;

  /**
   * Action data when type is 'action'.
   */
  actionData: {
    actionType: 'addParticipant' | 'deleteParticipant';
    data: {
      userId: string;
    };
  } | null;

  /**
   * ID of parent message.
   */
  replyTo: string | null;

  replyToDetail?: IMessageData | null;

  mentions: string[] | null;

  reactions: IReactionData[];
}

export type IMessageDataWithStringTimestamps = IMessageData & { createdAtDateTimeString: string };

// Funciones para operar con IMessageData:
export namespace IMessageData {
  /**
   * Obtiene un objeto IMessageData con valores por defecto establecidos.
   *
   * @param partialData Se usa para reemplazar los valores predeterminados (ignorando todas aquellas no definidas).
   */
  export const defaulted = (partialData: Partial<IMessageData> = {}): IMessageData => {
    const defaultData: IMessageData = {
      ...IBaseData.defaulted(partialData),

      // parentId: null,
      mediaId: null,
      content: '',
      contentType: 'text',
      senderId: '',
      chatId: '',
      seen: false,
      seenBy: {},
      actionData: null,
      replyTo: null,
      replyToDetail: null,
      mentions: [],
      reactions: [],
    };

    return defaultedData(partialData, defaultData);
  };

  /**
   * Convierte un objeto MessageSchema parcial a IMessageData normalizado.
   */
  export const normalizedFrom = (partialData: Partial<MessageSchema> | Partial<IMessageData>): IMessageData => {
    // Auxiliar para poder operar como si fuera cualquiera de los 2 tipos
    const mergedPartialData = partialData as Partial<MessageSchema> & Partial<IMessageData>;

    const dataFromSchema: Partial<IMessageData> = {
      ...IBaseData.normalizedFrom(partialData),

      content: mergedPartialData.message,
      contentType: mergedPartialData.typeMsg,
      senderId: mergedPartialData.emisor?.toString(),
      chatId: mergedPartialData.chat?.toString(),
      mediaId: mergedPartialData.document?.toString(),
      seen: mergedPartialData.seen,
      actionData: mergedPartialData.actionData,
      replyTo: mergedPartialData.replyTo?.toString(),
      replyToDetail: Array.isArray(partialData.replyToDetail)
        ? partialData.replyToDetail.length > 0
          ? normalizedFrom(partialData.replyToDetail[0])
          : null
        : partialData.replyToDetail,
      mentions: Array.isArray(mergedPartialData.mentions)
        ? mergedPartialData.mentions.map((mention) => mention.toString())
        : null,
      reactions: Array.isArray(mergedPartialData.reactions)
        ? mergedPartialData.reactions.map((reaction) => ({
            ...reaction,
            id: reaction._id.toString(),
            user: reaction.user.toString(),
            createdAt: new Date(reaction.createdAt),
          }))
        : [],
    };

    const seenBy = partialData.seenBy;

    if (Array.isArray(seenBy)) {
      // Se quita para que se agregue el valor por defecto
      delete partialData.seenBy;
    }

    const replyToDetail = partialData.replyToDetail;

    if (Array.isArray(replyToDetail)) {
      // Se quita para que se agregue el valor por defecto
      delete partialData.replyToDetail;
    }

    const data = defaultedData(
      omitBy(dataFromSchema, isNil),
      defaulted(
        // Si partialData no es un Partial<IMessageData>, ign
        partialData as Partial<IMessageData>
      )
    );

    data.contentType = data.contentType.toLowerCase();

    const actionDataUser = (partialData as MessageSchema).actionData?.data.user;

    // Detectar si viene del schema o de data
    if (data.actionData && !!actionDataUser) {
      data.actionData.data.userId = actionDataUser.toString();
    }

    // Se parsea el seenBy
    if (Array.isArray(seenBy)) {
      seenBy.forEach((seenByItem) => {
        if (!(data.seenBy[seenByItem.user.toString()] ?? undefined)) {
          // En algunos casos hay varias ocurrencias por un error del backend que agrega varias veces marcando como leído.
          // Con ésto, se usa la primera fecha.
          data.seenBy[seenByItem.user.toString()] = seenByItem.seenAt;
        }
      });
    }

    return data;
  };

  // TODO: Agregar más métodos para operar con mensajes
}
