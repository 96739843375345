import { useAuthenticationStore } from '@/stores/authentication.store';
import { useCurrentCompanyStore } from '@/stores/current-company.store';
import { useRolesStore } from '@/stores/roles.store';
import { isEmpty } from 'lodash-es';
import { DateTime } from 'luxon';
import * as Realm from 'realm-web';
import { BSON } from 'realm-web';
import { Observable, from } from 'rxjs';
import { ChatSchema } from '../models/schema/chats-schema';
import { MediaSchema } from '../models/schema/media.schema';
import { MessageSchema } from '../models/schema/message.schema';
import { RoleUserSchema } from '../models/schema/roleuser';
import { UsuariosSchema } from '../models/schema/usuarios-schema';
import { useAuthStore } from '../stores/auth-store';
import { useWindowDocumentStore } from '../stores/window-document.store';

export type DocumentChangeEvent<T extends globalThis.Realm.Services.MongoDB.Document> =
  | globalThis.Realm.Services.MongoDB.InsertEvent<T>
  | globalThis.Realm.Services.MongoDB.UpdateEvent<T>
  | globalThis.Realm.Services.MongoDB.ReplaceEvent<T>
  | globalThis.Realm.Services.MongoDB.DeleteEvent<T>;

// FIXME: Separa en 3 tipos diferentes
export interface IPaginationOptions {
  page?: number;

  /**
   * Cantidad máxima de elementos a obtener.
   *
   * @default 15
   */
  limit?: number;

  skip?: number;

  /**
   * ID desde el que se van a obtener los elementos (no incluido).
   */
  after?: BSON.ObjectId | string;

  /**
   * ID desde el que se van a obtener los elementos (no incluido).
   */
  before?: BSON.ObjectId | string;

  /**
   * ID desde el que se van a obtener los elementos (incluido).
   */
  since?: BSON.ObjectId | string;

  /**
   * ID hasta el que se van a obtener los elementos (incluido).
   */
  until?: BSON.ObjectId | string;
}

export interface IChatMessageFilters {
  /**
   * Filtra los mensajes para ese ID de chat específico.
   */
  chatId?: string | BSON.ObjectId;

  afterDate?: Date | undefined;
}

export interface IChatMessageSort {
  created_at?: 1 | -1;
  _id?: 1 | -1;
}

/**
 * FIXME: Convertir en un singleton inyectable.
 */
export class realmService {
  private static instance: realmService;
  private currentChatChangeStream: any;
  private chatsChangeStream: AsyncGenerator<DocumentChangeEvent<ChatSchema>, any, unknown> | null = null;
  private authStore = useAuthStore();
  private readonly oAuth2Store;

  private _db: globalThis.Realm.Services.MongoDBDatabase | null = null;
  private _app:
    | Realm.App<
        globalThis.Realm.DefaultFunctionsFactory & globalThis.Realm.BaseFunctionsFactory,
        { [x: string]: unknown }
      >
    | undefined;

  public currentUser:
    | Realm.User<
        globalThis.Realm.DefaultFunctionsFactory & globalThis.Realm.BaseFunctionsFactory,
        { [x: string]: unknown },
        globalThis.Realm.DefaultUserProfileData
      >
    | undefined;

  protected documentChangeSubscription: { unsubscribe: () => void } | undefined;

  public get db() {
    return this._db;
  }

  public get app() {
    if (!this._app) {
      this._app = new Realm.App({
        id: import.meta.env.VITE_REALM_APP_ID,
      });
    }

    return this._app;
  }

  private constructor() {
    this.oAuth2Store = useAuthenticationStore();
  }

  public static getInstance(): realmService {
    if (!realmService.instance) {
      realmService.instance = new realmService();
    }
    return realmService.instance;
  }

  /**
   * Conecta al usuario actual al servicio de Realm.
   */
  async connect({ force }: { force?: boolean } = {}): Promise<globalThis.Realm.Services.MongoDBDatabase> {
    // debugger;
    if (!this.db || !this.currentUser || force) {
      // FIXME: Esto debe hacerse con el rastreo de autenticación

      if (force) {
        await this.disconnect();
      }

      if (!this.currentUser?.isLoggedIn) {
        if (this.documentChangeSubscription) {
          this.documentChangeSubscription.unsubscribe();
        }

        await this.authStore.loadStore();

        const currentUserToken = this.oAuth2Store.token ?? this.authStore.token;

        const credentials = Realm.Credentials.function({
          token: currentUserToken,
        });

        // Log in an anonymous user
        try {
          this.currentUser = await this.app.logIn(credentials);

          const rolesStore = useRolesStore();

          await rolesStore.loadRoles();

          const currentCompanyStore = useCurrentCompanyStore();

          await Promise.all([
            currentCompanyStore.refreshData(),
            currentCompanyStore.fetchSettings(),
          ]);

          const documentStore = useWindowDocumentStore();

          this.documentChangeSubscription = documentStore.onVisible(async (hiddenSince) => {
            // console.log('Minutes hidden:', DateTime.now().diff(hiddenSince, 'minutes').minutes);
            const MIN_IDLE_TIME_IN_MINUTES = 30;

            if (this.currentUser) {
              if (hiddenSince.diff(DateTime.now(), 'minutes').minutes > MIN_IDLE_TIME_IN_MINUTES) {
                await this.refreshAccessToken();
              }
            }
          });
        } catch (error) {
          console.error('Error logging in:', error);

          this.disconnect();

          throw error;
        }

        // console.log('Realm Connection User', this.currentUser);
      }

      // Get a reference to the Atlas cluster
      const client = this.currentUser.mongoClient('mongodb-atlas');

      // Get a reference to the database
      this._db = client.db(import.meta.env.VITE_MONGO_DB_DATABASE);
    }

    return this.db!;
  }

  async refreshAccessToken() {
    await this.currentUser?.refreshAccessToken();
  }

  /**
   * Si hay una conexión activa, la cierra,
   */
  async disconnect(): Promise<void> {
    this.documentChangeSubscription?.unsubscribe();
    await this.currentUser?.logOut();

    this._db = null;
  }

  public async getMediaById(...mediaIds: BSON.ObjectId[] | string[]): Promise<MediaSchema[]> {
    // TODO: Mover a un servicio especializado
    const db = await this.connect();

    const documentCollection = db.collection<MediaSchema>(MediaSchema.COLLECTION_NAME);

    return documentCollection.find({
      _id: {
        $in: mediaIds.map((id) => new BSON.ObjectId(id)),
      },
    });
  }

  public async getMediaByMessageId(...messageIds: BSON.ObjectId[] | string[]): Promise<MediaSchema[]> {
    // TODO: Mover a un servicio especializado

    const db = await this.connect();

    const documentCollection = db.collection<MediaSchema>(MediaSchema.COLLECTION_NAME);

    return documentCollection.find({
      message: {
        $in: messageIds.map((id) => new BSON.ObjectId(id)),
      },
    });
  }

  /**Metodo para consultar la informacion individual de un usuario */
  public async getUserDetails(userId: string) {
    const db = await this.connect();
    const usersCollection = db.collection('usuarios'); // Asegúrate de que el nombre de la colección sea correcto
    return usersCollection.findOne({ _id: new BSON.ObjectId(userId) });
  }

  /**  Metodo usado para consultar los usuarios los lista de IDs */
  public async getUsersDetails(userIds: BSON.ObjectID[]) {
    const db = await this.connect();
    const usersCollection = db.collection<UsuariosSchema>('usuarios'); // Asegúrate de que el nombre de la colección sea correcto

    // Utiliza el operador $in para buscar todos los usuarios cuyos IDs estén en la lista userIds
    return usersCollection.find({ _id: { $in: userIds } });
  }

  /**
   * Paginates the latest User's available chats.
   */
  public async getUserChats(
    userId: BSON.ObjectID | string,
    {
      page,
      skip,
      limit = 15,
      since,
    }: { page?: number; limit?: number; skip?: number; since?: BSON.ObjectID | string } = {}
  ): Promise<ChatSchema[]> {
    const db = await this.connect();

    const collection = db.collection<ChatSchema>('chats');

    if (limit < 1) {
      throw new Error('Limit must be greater than 0');
    }

    if (page !== undefined) {
      if (page < 1) {
        throw new Error('Page must be greater than 0');
      }

      skip = (page - 1) * limit;
    } else {
      if (!skip) {
        skip = 0;
      }
    }

    if (skip < 0) {
      throw new Error('Skip must not be greater negative');
    }

    let $match: Record<string, unknown> = {
      'participants.user': new BSON.ObjectId(userId),
    };

    if (since) {
      $match['_id'] = { $gt: new BSON.ObjectId(since) };
    }

    const pipeline = [
      {
        $match,
      },
      // FIXME: Cuando no tiene definido, no aparece
      // {
      //   $addFields: {
      //     sort_helper: {
      //       // create a new field called "sortField"

      //       $cond: {
      //         if: {
      //           $eq: [
      //             '$last_message_at',
      //             [
      //               null,
      //               undefined,
      //             ],
      //           ],
      //         },
      //         then: 1,
      //         else: 0,
      //       },
      //     },
      //   },
      // },

      {
        $sort: {
          // sort_helper: -1,
          last_message_at: -1, // Ordena por la fecha del último mensaje
          created_at: -1, // Ordena secundariamente por la creación
        },
      },

      // Paginación debe ir al final 🤡
      {
        $skip: skip,
      },
      {
        $limit: limit,
      },
    ];

    return await collection.aggregate(pipeline);
  }

  /**
   * Obtiene el primer mensaje no leído de un chat específico.
   */
  public async getFirstUnreadChatMessage(
    chatId: BSON.ObjectID | string,
    userId: BSON.ObjectID | string,
    afterDate?: Date | undefined
  ): Promise<MessageSchema | null> {
    const db = await this.connect();

    const collection = db.collection<MessageSchema>('messages');

    const filters = {
      chat: new BSON.ObjectId(chatId),

      typeMsg: {
        // Ignora los mensajes de tipo 'action'
        $ne: 'action',
      },
      emisor: {
        $ne: new BSON.ObjectId(userId),
      },
      seenBy: {
        $not: {
          $elemMatch: {
            user: new BSON.ObjectId(userId),
          },
        },
      },
    } as Record<keyof MessageSchema, unknown>;

    // FIXME: Inyectar directamente a la query en vez de hacerlo por un parámetro
    if (afterDate) {
      filters['created_at'] = {
        $gte: afterDate,
      };
    }

    // console.log('afterDate', afterDate);

    return collection.findOne(filters, { sort: { _id: 1 } });
  }

  /**
   * Paginates the Chats's messages.
   */
  public async getChatMessages(
    filters: IChatMessageFilters = {},
    { page, skip, limit, after, since, until, before }: IPaginationOptions = {},
    sortBy: IChatMessageSort = {}
  ): Promise<MessageSchema[]> {
    // if (!chatId) {
    //   return [];
    // }

    const db = await this.connect();

    const collection = db.collection<MessageSchema>('messages');

    // TODO: Mejorar la paginación para buscar por cursor

    if (page !== undefined) {
      if (page < 1) {
        throw new Error('Page must be greater than 0.');
      }

      if (!limit) {
        limit = 15;
      } else if (limit < 1) {
        throw new Error('Limit must be greater than 0.');
      }

      skip = (page - 1) * limit;
    } else {
      if (!skip) {
        skip = 0;
      }
    }

    if (skip < 0) {
      throw new Error('Skip must not be negative');
    }

    /**
     * Filtros y pre-ordenado
     */
    const paginationFilters = [] as Record<string, unknown>[];

    if (filters.chatId) {
      paginationFilters.push({
        // Primero se filtra por los IDs de chats
        $match: {
          chat: { $eq: new BSON.ObjectId(filters.chatId) },
        },
      });
    }

    if (filters.afterDate) {
      paginationFilters.push({
        $match: {
          created_at: { $gte: filters.afterDate },
        },
      });
    }

    const cursorPagination = {
      id: since || after || until || before || null,
      operator: since ? '$gte' : after ? '$gt' : until ? '$lte' : before ? '$lt' : '',
    };

    if (cursorPagination.id) {
      // paginationFilters.push({
      //   // Para traer desde el especificado, se debe pre-ordenar por el ID
      //   $sort: {
      //     _id: 1,
      //   },
      // });

      paginationFilters.push({
        // Para traer desde el especificado, se debe pre-ordenar por el ID
        $match: {
          _id: {
            [cursorPagination.operator]: new BSON.ObjectId(cursorPagination.id),
          },
        },
      });

      // Si no hay una paginación especificada, se filtra para exprimir el orden de paginación
      if (Object.keys(sortBy).length === 0) {
        sortBy = {
          _id: since || after ? 1 : -1,
        };
      }
    } else {
      if (Object.keys(sortBy).length === 0) {
        sortBy = {
          // Por defecto filtra por la fecha en orden invertido
          _id: -1, // _id puede reemplazar a created_at
        };
      }
    }

    // console.log('filters', filters);

    if (!limit) {
      console.warn('Paginating without filter');
    }

    const pipeline = [
      ...paginationFilters,
      {
        $lookup: {
          from: 'messages',
          localField: 'replyTo',
          foreignField: '_id',
          as: 'replyToDetail',
        },
      },
      {
        $sort: sortBy,
      },
      {
        $skip: skip,
      },

      // Si no se especifica un límite, se ignora
      !limit
        ? {}
        : {
            $limit: limit,
          },
    ].filter((entry) => !isEmpty(entry));

    return await collection.aggregate(pipeline);
  }

  /**
   * Gets the list of chats for the specified user.
   *
   * @deprecated Use chatsStore.loadMoreChats() or this.getUserChats()
   * @param id User ID.
   */
  public getChats(id: string): Observable<any> {
    // console.log('🅰️ getChats', id);
    const chatsObservable = from(
      new Promise(async (resolve, reject) => {
        try {
          const db = await this.connect();
          const collection = db.collection<ChatSchema>('chats');
          const oidUser = new BSON.ObjectId(id);

          const pipeline = [
            {
              $match: {
                'participants.user': oidUser,
              },
            },
            {
              $lookup: {
                from: 'usuarios',
                localField: 'participants.user',
                foreignField: '_id',
                as: 'participantDetails',
              },
            },
            {
              $lookup: {
                from: 'messages',
                let: { chatId: '$_id' },
                pipeline: [
                  {
                    $match: {
                      $expr: {
                        $eq: [
                          '$chat',
                          '$$chatId',
                        ],
                      },
                    },
                  },
                  { $sort: { created_at: -1 } },
                  { $limit: 1 },
                  {
                    $project: {
                      message: 1,
                      emisor: 1,
                      typeMsg: 1,
                      _id: 1,
                    },
                  },
                ],
                as: 'lastMessage',
              },
            },
            {
              $lookup: {
                from: 'messages',
                let: { chatId: '$_id', userId: oidUser },
                pipeline: [
                  {
                    $match: {
                      $expr: {
                        $and: [
                          {
                            $eq: [
                              '$chat',
                              '$$chatId',
                            ],
                          },
                          {
                            $eq: [
                              '$seen',
                              false,
                            ],
                          },
                          {
                            $ne: [
                              '$emisor',
                              '$$userId',
                            ],
                          },
                        ],
                      },
                    },
                  },
                  { $count: 'unseenCount' },
                ],
                as: 'unseenMessages',
              },
            },
            {
              $addFields: {
                counter: {
                  $ifNull: [
                    {
                      $arrayElemAt: [
                        '$unseenMessages.unseenCount',
                        0,
                      ],
                    },
                    0,
                  ],
                },
              },
            },
            {
              $unwind: {
                path: '$lastMessage',
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $sort: {
                last_message_at: -1, // Ordena por la fecha del último mensaje
                created_at: -1, // Ordena segundariamente por la creación
              },
            },
          ];

          const myChats = await collection.aggregate(pipeline);

          resolve(myChats);
        } catch (error) {
          console.error('Error:', error);
          reject(error);
        }
      })
    );

    return chatsObservable;
  }

  public getChatsx(id: string): Observable<any> {
    const chatsObservable = from(
      new Promise(async (resolve, reject) => {
        try {
          const db = await this.connect();
          const collection = db.collection('chats');
          const oidUser = new BSON.ObjectId(id);

          const pipeline = [
            {
              $match: {
                'participants.user': oidUser,
              },
            },
            {
              $lookup: {
                from: 'usuarios',
                localField: 'participants.user',
                foreignField: '_id',
                as: 'participantDetails',
              },
            },
            {
              $lookup: {
                from: 'messages',
                let: { chatId: '$_id' },
                pipeline: [
                  {
                    $match: {
                      $expr: {
                        $eq: [
                          '$chat',
                          '$$chatId',
                        ],
                      },
                      seen: false,
                    },
                  },
                  { $count: 'unreadMessages' },
                ],
                as: 'counter',
              },
            },
            {
              $lookup: {
                from: 'messages',
                let: { chatId: '$_id' },
                pipeline: [
                  {
                    $match: {
                      $expr: {
                        $eq: [
                          '$chat',
                          '$$chatId',
                        ],
                      },
                    },
                  },
                  { $sort: { createdAt: -1 } },
                  { $limit: 1 },
                  {
                    $project: {
                      message: 1,
                      sender: 1, // Incluir el emisor del mensaje
                      typeMsg: 1, // Incluir el tipo de mensaje
                      _id: 0,
                    },
                  },
                ],
                as: 'lastMessage',
              },
            },
            {
              $unwind: {
                path: '$lastMessage',
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $addFields: {
                counter: {
                  $ifNull: [
                    {
                      $arrayElemAt: [
                        '$counter.unreadMessages',
                        0,
                      ],
                    },
                    0,
                  ],
                },
              },
            },
            {
              $project: { counter: 0 },
            },
          ];

          const myChats = await collection.aggregate(pipeline);
          resolve(myChats);
        } catch (error) {
          console.error('Error:', error);
          reject(error);
        }
      })
    );

    return chatsObservable;
  }

  public getChatsAgregacion2(id: string): Observable<any> {
    const chatsObservable = from(
      new Promise(async (resolve, reject) => {
        try {
          const db = await this.connect();
          const collection = db.collection('chats');
          const oidUser = new BSON.ObjectId(id);

          const pipeline = [
            {
              $match: {
                'participants.user': oidUser,
              },
            },
            {
              $lookup: {
                from: 'usuarios',
                localField: 'participants.user',
                foreignField: '_id',
                as: 'participantDetails',
              },
            },
            {
              $lookup: {
                from: 'messages',
                let: { chatId: '$_id' },
                pipeline: [
                  {
                    $match: {
                      $expr: {
                        $eq: [
                          '$chat',
                          '$$chatId',
                        ],
                      },
                    },
                  },
                  { $sort: { createdAt: -1 } },
                  { $limit: 1 },
                  {
                    $project: {
                      message: 1,
                      emisor: 1, // Incluir el emisor del mensaje
                      typeMsg: 1, // Incluir el tipo de mensaje
                      _id: 0,
                    },
                  },
                ],
                as: 'lastMessage',
              },
            },
            {
              $unwind: {
                path: '$lastMessage',
                preserveNullAndEmptyArrays: true,
              },
            },
          ];

          const myChats = await collection.aggregate(pipeline);
          resolve(myChats);
        } catch (error) {
          console.error('Error:', error);
          reject(error);
        }
      })
    );

    return chatsObservable;
  }

  public getChatsAgregacion1(id: string): Observable<any> {
    const chatsObservable = from(
      new Promise(async (resolve, reject) => {
        try {
          const db = await this.connect();
          const collection = db.collection('chats');
          const oidUser = new BSON.ObjectId(id);

          const pipeline = [
            {
              $match: {
                'participants.user': oidUser,
              },
            },
            {
              $lookup: {
                from: 'usuarios',
                localField: 'participants.user',
                foreignField: '_id',
                as: 'participantDetails',
              },
            },
            {
              $lookup: {
                from: 'messages',
                let: { chatId: '$_id' },
                pipeline: [
                  {
                    $match: {
                      $expr: {
                        $eq: [
                          '$chat',
                          '$$chatId',
                        ],
                      },
                    },
                  },
                  { $sort: { createdAt: -1 } },
                  { $limit: 1 },
                  { $project: { message: 1, _id: 0 } },
                ],
                as: 'lastMessage',
              },
            },
            {
              $unwind: {
                path: '$lastMessage',
                preserveNullAndEmptyArrays: true,
              },
            },
            {
              $addFields: {
                lastMessage: '$lastMessage.message',
              },
            },
          ];

          const myChats = await collection.aggregate(pipeline);
          resolve(myChats);
        } catch (error) {
          console.error('Error:', error);
          reject(error);
        }
      })
    );

    return chatsObservable;
  }

  // Método para obtener los chats del usuario
  public getChatsNormal(id: string, callback: Function): Observable<any> {
    // Envolver todo el proceso en una nueva Promesa y luego convertir esa Promesa en un Observable
    const chatsObservable = from(
      new Promise(async (resolve, reject) => {
        try {
          const db = await this.connect();
          const collection = db.collection('chats');
          const oidUser = new BSON.ObjectId(id);
          const myChats = await collection.find({
            participants: {
              $elemMatch: { user: oidUser },
            },
          });
          let userList = new Set<BSON.ObjectID>();
          // Recorrer los chats y enriquecerlos con detalles de los participantes
          for (const chat of myChats) {
            for (const participant of chat.participants) {
              userList.add(participant.user);
            }
          }
          const userIdsArray = Array.from(userList);

          // Obtener detalles de todos los usuarios en una sola consulta
          const usersDetails = await this.getUsersDetails(userIdsArray);
          callback(usersDetails);
          for (const chat of myChats) {
            for (const participant of chat.participants) {
              const userDetails = usersDetails.find((e) => JSON.stringify(e._id) == JSON.stringify(participant.user));
              participant.userDetails = userDetails;
            }
          }
          let chatIds = myChats.map((e) => e._id);

          let lastMessages = await this.getLastMessages(chatIds);

          for (const chat of myChats) {
            let msgItem = lastMessages.find((e: any) => JSON.stringify(e._id) == JSON.stringify(chat._id));
            if (msgItem) {
              chat.message = msgItem.lastMessage;
            }
          }
          //         const subscription = from(onUpdateWatcher)
          resolve(myChats);
        } catch (error) {
          console.error('Error:', error);
          reject(error);
        }
      })
    );

    return chatsObservable;
  }

  public async getLastMessages(chatsIds: BSON.ObjectId[]) {
    const db = await this.connect();
    const collection = db.collection('messages');

    // Construye el pipeline de agregación
    const lastMessagesAggregation = [
      {
        $match: {
          chat: { $in: chatsIds },
        },
      },
      { $sort: { createdAt: -1 } },
      {
        $group: {
          _id: '$chat',
          lastMessage: { $last: '$$ROOT' },
        },
      },
    ];

    // Ejecutar la agregación para obtener el último mensaje de cada chat
    const result = await collection.aggregate(lastMessagesAggregation);
    return result;
  }

  public async getmessages(idchat: string) {
    try {
      const db = await this.connect();

      const collection = db.collection('messages');

      const oidChat = new BSON.ObjectId(idchat);
      const messagesQuery = collection.find({
        chat: oidChat,
      });

      const messages = await messagesQuery;

      const onUpdateWatcher = collection.watch({
        filter: {
          operationType: 'update',
          'fullDocument.chat': oidChat,
        },
      });

      const subscription = from(onUpdateWatcher);

      return { messages, subscription };
    } catch (error) {
      console.error('Error:', error);
    }
  }
  public async getRoles(): Promise<RoleUserSchema[]> {
    const db = await this.connect();
    const collection = db.collection<RoleUserSchema>('roleusers');

    return collection.find({
      company: new BSON.ObjectId(import.meta.env.VITE_APP_COMPANY_ID),
    });
  }
  public async watchForMessageChanges(callback: Function) {
    // FIXME: Se trae todos los mensajes; debe filtrarse por los chats
    try {
      const db = await this.connect();
      const collection = db.collection('messages');

      // Configura el Change Stream para escuchar cambios
      this.currentChatChangeStream = collection.watch();
      for await (const change of this.currentChatChangeStream) {
        callback(change);
      }
    } catch (error) {
      console.error('Error al configurar el Change Stream:', error);
    }
  }

  /**
   * @deprecated Use ChatsStore
   */
  public async watchForChatChanges(
    userId: string | BSON.ObjectID,
    callback: {
      (change: DocumentChangeEvent<ChatSchema>): void;
    }
  ) {
    try {
      const db = await this.connect();
      const collection = db.collection<ChatSchema>('chats');

      this.chatsChangeStream = collection.watch({
        filter: {
          'fullDocument.participants.user': new BSON.ObjectID(userId),
        },
      }) as AsyncGenerator<DocumentChangeEvent<ChatSchema>, any, unknown>;
      // FIXME: Controlar eventos sin documentos (DropEvent, RenameEvent, etc)

      for await (const change of this.chatsChangeStream) {
        // console.log({ change });
        callback(change);
      }
    } catch (error) {
      console.error('Error al configurar el Change Stream:', error);
    }
  }

  public async stopWatchingMessagesChanges() {
    if (this.currentChatChangeStream) {
      await this.currentChatChangeStream.return();
      this.currentChatChangeStream = null;
    }
  }

  public async stopWatchingChatChanges() {
    if (this.chatsChangeStream) {
      await this.chatsChangeStream.return(null);
      this.chatsChangeStream = null;
    }
  }
}
