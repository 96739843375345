import CryptoJS from 'crypto-js';
import { LoginRequest } from '../models/services/auth/login-req';
import { UserModel } from '../models/services/auth/user';

export default class LocalStorageService {
  private secretKey = '7gXjKH9faVz4QpEvT3mZ5Rs8YB6nW2Ud';
  private USER_KEY = 'user';
  private TOKEN_KEY = 'token';
  private LOGIN_KEY = 'login';
  private ROLE_KEY = 'role';
  private REMEMBER_KEY = 'remember';

  public setUser(user: UserModel) {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(user), this.secretKey).toString();
    localStorage.setItem(this.USER_KEY, ciphertext);
  }

  public getUser(): UserModel | undefined {
    const ciphertext = localStorage.getItem(this.USER_KEY);
    if (ciphertext) {
      const bytes = CryptoJS.AES.decrypt(ciphertext, this.secretKey);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    }
    return undefined;
  }
  public logout() {
    if (this.getRemember()) {
      let credentials = this.getLogin();
      localStorage.clear();
      this.setRemember(true);
      this.setLogin(credentials as LoginRequest);
    } else {
      localStorage.clear();
    }
  }

  public setToken(token: string) {
    const ciphertext = CryptoJS.AES.encrypt(token, this.secretKey).toString();
    localStorage.setItem(this.TOKEN_KEY, ciphertext);
  }

  public authenticate(): boolean {
    return !!localStorage.getItem(this.TOKEN_KEY);
  }

  public getToken(): string | undefined {
    const ciphertext = localStorage.getItem(this.TOKEN_KEY);
    if (ciphertext) {
      const bytes = CryptoJS.AES.decrypt(ciphertext, this.secretKey);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedData;
    }
    return undefined;
  }
  public setRole(role: string) {
    const ciphertext = CryptoJS.AES.encrypt(role, this.secretKey).toString();
    localStorage.setItem(this.ROLE_KEY, ciphertext);
  }
  public getRole(): string | undefined {
    const ciphertext = localStorage.getItem(this.ROLE_KEY);
    if (ciphertext) {
      const bytes = CryptoJS.AES.decrypt(ciphertext, this.secretKey);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedData;
    }
    return undefined;
  }
  public getEmail(): string | undefined {
    let user = this.getUser();
    return user?.email ?? undefined;
  }
  public getAvatar(): string | undefined {
    let user = this.getUser();
    return user?.avatar ?? undefined;
  }
  public getName(): string | undefined {
    let user = this.getUser();
    return user?.fullName ?? undefined;
  }
  public getId(): string | undefined {
    let user = this.getUser();
    return user?.id ?? undefined;
  }
  //#region Datos de inicio de sesión
  public setLogin(login: LoginRequest) {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(login), this.secretKey).toString();
    localStorage.setItem(this.LOGIN_KEY, ciphertext);
  }

  public resetLogin() {
    localStorage.removeItem(this.LOGIN_KEY);
  }

  public getLogin(): LoginRequest | undefined {
    try {
      const ciphertext = localStorage.getItem(this.LOGIN_KEY);
      if (ciphertext) {
        const bytes = CryptoJS.AES.decrypt(ciphertext, this.secretKey);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        return decryptedData;
      }
    } catch (error) {
      console.error('Error on decrypting data', error);
    }

    return undefined;
  }
  public setRemember(remember: boolean) {
    localStorage.setItem(this.REMEMBER_KEY, JSON.stringify(remember));
  }
  public getRemember(): boolean {
    const remember = localStorage.getItem(this.REMEMBER_KEY);
    return remember ? JSON.parse(remember) : false;
  }
  //#endregion
}
