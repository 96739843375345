import { IErrorResponse, ISuccessResponse } from '@/core/axios';
import { useAuthenticationStore } from '@/stores/authentication.store';
import {
  RouteLocationNamedRaw,
  RouteLocationPathRaw,
  RouteLocationRaw,
  createRouter,
  createWebHistory,
} from 'vue-router';
import routes from './routes';

const router = createRouter({
  routes,
  history: createWebHistory(),
});

router.beforeEach(async (to, from) => {
  let next: RouteLocationRaw | boolean = true;

  const authMetadata = to.meta.auth;

  // Guardián de autenticación
  if (authMetadata && (authMetadata.requireGuest || authMetadata.requireAuthenticated)) {
    // Run auth check before navigating to the route
    const authenticationStore = useAuthenticationStore();

    let authenticated = false;
    let authorized = false;

    let resp: IErrorResponse<unknown> | ISuccessResponse<any> | undefined;

    if (authenticationStore.hasAuthorizedToken === undefined) {
      authenticated = true;
      resp = await authenticationStore.refreshToken();

      authorized = resp?.statusCode !== 403;

      if (authorized) {
        resp = await authenticationStore.syncTokenUser();

        authorized = resp?.statusCode !== 403;
      }
    }

    if (authMetadata.requireAuthenticated) {
      if (authenticationStore.hasAuthorizedToken === false) {
        next = authMetadata.redirectPathIfUnauthorized || false;
      } else if (authenticated && !authorized) {
        next = { name: 'auth.login', query: { reason: 'invalid-token' } };
      }
    } else {
      if (authenticationStore.hasAuthorizedToken) {
        next = authMetadata.redirectPathIfUnauthorized || false;
      }
    }

    // Run guest check before navigating to the route
  }

  if (typeof next === 'boolean') {
    return next;
  }

  // ❗️ Avoid an infinite redirect
  if (typeof next === 'string') {
    if (to.path === next) {
      // Si es la misma ruta a redirigir, mejor se usa `false` para simplemente impedir la navegación.
      next = false;
    }
  } else if ((next as RouteLocationPathRaw).path === to.path) {
    next = false;
  } else if ((next as RouteLocationNamedRaw).name === to.name) {
    next = false;
  }

  return next;
});

export default router;
