import { isNil, omitBy } from 'lodash-es';
import { defaultedData } from '../../utils/helpers';
import { IBaseData } from '../utils/base-data';
import { RoleUserSchema } from './roleuser';

/**
 * Roles del usuario.
 */
export interface IRoleData extends IBaseData {
  name: string;
  companyId: string;
  active: boolean;
}

// Funciones para operar con IRoleData:
export namespace IRoleData {
  /**
   * Obtiene un objeto IMessageData con valores por defecto establecidos.
   *
   * @param partialData Se usa para reemplazar los valores predeterminados (ignorando todas aquellas no definidas).
   */
  export const defaulted = (partialData: Partial<IRoleData> = {}): IRoleData => {
    const defaultData: IRoleData = {
      ...IBaseData.defaulted(partialData),

      name: '',
      companyId: 'text',
      active: false,
    };

    return defaultedData(partialData, defaultData);
  };

  /**
   * Convierte un objeto MessageSchema parcial a IMessageData normalizado.
   */
  export const normalizedFrom = (partialData: Partial<RoleUserSchema> | Partial<IRoleData>): IRoleData => {
    // Auxiliar para poder operar como si fuera cualquiera de los 2 tipos
    const mergedPartialData = partialData as Partial<RoleUserSchema> & Partial<IRoleData>;

    const dataFromSchema: Partial<IRoleData> = {
      ...IBaseData.normalizedFrom(partialData),

      active: mergedPartialData.status === 'active' || mergedPartialData.active === true,
      companyId: mergedPartialData.company?.toString(),
      name: mergedPartialData.name,
    };

    return defaultedData(
      omitBy(dataFromSchema, isNil),
      defaulted(
        // Si partialData no es un Partial<IMessageData>, ign
        partialData as Partial<IRoleData>
      )
    );
  };
}
