import { defaultedData } from '@/utils/helpers';
import { SchemaBase } from './schema-base';
import { ISettingable } from './setting-base';

export interface IWebChatsSetting {
  background_color: string;
  background_image: string;
}

export namespace IWebChatsSetting {
  export const defaulted = (partialValue?: Partial<IWebChatsSetting> | undefined): IWebChatsSetting => {
    // TODO: Terminar de ajustar valores predeterminados

    const defaultData: IWebChatsSetting = {
      background_color: 'transparent',
      background_image: '',
    };

    if (!partialValue) {
      return defaultData;
    }

    return defaultedData(partialValue, defaultData);
  };
}

export interface ICompanySettingsMap {
  /**
   * Configuración para el chat en la web, agrupados por el tema claro y oscuro.
   */
  'web:chats': Record<'light' | 'dark', IWebChatsSetting>;

  // TODO: Agregar resto de settings.
}

export type CompanySettingKey = keyof ICompanySettingsMap;

export namespace ICompanySettingsMap {
  export const defaulted = (partialValue?: Partial<ICompanySettingsMap>): ICompanySettingsMap => {
    const defaultData: ICompanySettingsMap = {
      'web:chats': defaultedValue('web:chats', partialValue?.['web:chats']),
    };

    if (!partialValue) {
      return defaultData;
    }

    return defaultedData(partialValue, defaultData);
  };

  /**
   * @throws Error if the key is not compatible.
   */
  export const defaultedValue = <TKey extends CompanySettingKey>(
    key: TKey,
    partialValue?: Partial<ICompanySettingsMap[TKey]> | undefined
  ): ICompanySettingsMap[TKey] => {
    switch (key) {
      case 'web:chats': {
        return {
          dark: IWebChatsSetting.defaulted(partialValue?.dark ?? {}),
          light: IWebChatsSetting.defaulted(partialValue?.light ?? {}),
        };
      }
    }

    throw new Error(`Unknown company setting key: ${key}. Check its compatibility.`);
  };
}

/**
 * Estructura en crudo desde la base de datos de la colección `company_settings`.
 */
export interface CompanySettingSchema<TKey extends CompanySettingKey>
  extends SchemaBase,
    ISettingable<TKey, Partial<ICompanySettingsMap[TKey]>> {
  //
}

export namespace CompanySettingSchema {
  export const COLLECTION_NAME = 'company_settings';
}
