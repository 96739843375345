// markdownPlugin.ts
import MarkdownIt from 'markdown-it';
import { App, getCurrentInstance } from 'vue';

export const useMarkdown = (): MarkdownIt => {
  const instance = getCurrentInstance();
  return instance?.appContext.config.globalProperties.$markdown;
};

export default {
  install: (app: App) => {
    const md = new MarkdownIt('zero').enable([
      'strikethrough',
      'link',
      'escape',
      'autolink',
      'newline',
      'emphasis',
      'text',
      'backticks',
      'code',
      'blockquote',
      'normalize',
    ]);

    app.config.globalProperties.$markdown = md;
  },
};
