import { isPlainObject } from 'lodash-es';

/**
 * @deprecated Use SuccessApiResponse
 */
export interface ApiResponse<T> {
  ok: boolean;
  msg: string;
  body: T;
}

export interface IFailedApiResponse<T = unknown> {
  ok: false;
  msg: string;
  body: T;
  code?: string;
  type?: string;
}

export interface ISuccessApiResponse<T = unknown> {
  ok: true;
  msg?: string;
  body: T;
}

export namespace ISuccessApiResponse {
  export const is = <T = unknown>(response: any): response is ISuccessApiResponse<T> => {
    if (!isPlainObject(response)) {
      return false;
    }

    if (!Object.hasOwn(response, 'ok')) {
      return false;
    }

    if (response.ok === false) {
      return false;
    }

    if (Object.keys(response).length === 1) {
      return true;
    }

    return Object.hasOwn(response, 'msg') || Object.hasOwn(response, 'body');
  };
}

export namespace IFailedApiResponse {
  export const is = <T = unknown>(response: any): response is IFailedApiResponse<T> => {
    if (!isPlainObject(response)) {
      return false;
    }

    if (!Object.hasOwn(response, 'ok') || !Object.hasOwn(response, 'msg')) {
      return false;
    }

    if (response.ok === true) {
      return false;
    }

    return Object.hasOwn(response, 'body') || Object.hasOwn(response, 'code') || Object.hasOwn(response, 'type');
  };
}
