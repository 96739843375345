import { defaultedData } from '@/utils/helpers';
import { IBaseData } from '../utils/base-data';
import { CompanySettingKey, CompanySettingSchema, ICompanySettingsMap } from './company-setting.schema';
import { ISettingable } from './setting-base';

export interface ICompanySettingData<TKey extends CompanySettingKey>
  extends IBaseData,
    ISettingable<TKey, ICompanySettingsMap[TKey]> {
  //
}

export namespace ICompanySettingData {
  /**
   * Obtiene un objeto data con valores por defecto establecidos.
   *
   * @param partialData Se usa para reemplazar los valores predeterminados (ignorando todas aquellas no definidas).
   */
  export const defaulted = <TKey extends CompanySettingKey>(
    partialData: Partial<ICompanySettingData<TKey>> = {}
  ): ICompanySettingData<TKey> => {
    // console.log('⭕', { partialData });
    if (!partialData.key) {
      throw new Error('Missing company setting key.');
    }

    const defaultData: ICompanySettingData<TKey> = {
      ...IBaseData.defaulted(partialData),
      key: partialData.key,
      value: ICompanySettingsMap.defaultedValue(partialData.key, partialData.value ?? {}),
    };

    return defaultedData(partialData, defaultData);
  };

  /**
   * Convierte un objeto CompanySettingSchema parcial a ICompanySettingData normalizado.
   */
  export const normalizedFrom = <TKey extends CompanySettingKey>(
    partialData: Partial<CompanySettingSchema<TKey>> | Partial<ICompanySettingData<TKey>>
  ): ICompanySettingData<TKey> => {
    // Auxiliar para poder operar como si fuera cualquiera de los 2 tipos
    const mergedPartialData = partialData as Partial<CompanySettingSchema<TKey>> & Partial<ICompanySettingData<TKey>>;

    const dataFromSchema: Partial<ICompanySettingData<TKey>> = {
      ...IBaseData.normalizedFrom(mergedPartialData),
      key: mergedPartialData.key,
      value: mergedPartialData.key
        ? ICompanySettingsMap.defaultedValue(mergedPartialData.key, mergedPartialData.value ?? {})
        : undefined,
    };

    return defaulted(dataFromSchema);
  };
}
