import { defineStore } from 'pinia';
import { BSON } from 'realm-web';
import { Observable, Subscription, from, shareReplay } from 'rxjs';
import { IUserData, UsuariosSchema, mapUserSchemaToData } from '../models/schema/usuarios-schema';
import { DocumentChangeEvent, realmService } from '../services/realm.service';
import { useChatsStore } from './chats.store';

/**
 * Usuarios con los que el usuario autenticado tiene chats activos.
 *
 * Mantiene un stream de la data de cada uno.
 */
export const useUsersStore = defineStore('users', {
  state: () => ({
    userId: null as string | null,

    /**
     * Usuarios cargados indexados por su ID.
     */
    itemsMap: {} as Record<string, IUserData | null>, // Corregir normalización como en los chats

    /**
     * Stream con los cambios de los usuarios.
     */
    itemsChanges$: null as Observable<DocumentChangeEvent<UsuariosSchema>> | null,

    itemsChangesSubscription: null as Subscription | null,
  }),

  getters: {
    /**
     * IDs de usuarios no nulos (que sí tienen data cargada).
     */
    ids(): string[] {
      return Object.entries(this.itemsMap)
        .filter(
          ([
            key,
            val,
          ]) => val !== null
        )
        .flatMap(
          ([
            key,
            val,
          ]) => key
        );
    },
  },

  actions: {
    async init(userId: string | BSON.ObjectID | null, options: { force?: boolean } = {}): Promise<void> {
      const chatsStore = useChatsStore();

      if (!userId) {
        this.clear();

        await chatsStore.init(null);

        return;
      }

      if (!options.force && userId.toString() === this.userId) {
        if (this.itemsChanges$ && !!this.itemsChangesSubscription && !this.itemsChangesSubscription.closed) {
          // TODO: Add an option to force to reset the subscription
          return;
        }
      }

      await chatsStore.init(userId);

      await this.addItemById(userId);
    },

    /**
     * Agrega un usuario a la lista. Si no tiene data cargada, se incluirá.
     *
     * @param userIds
     * @returns
     */
    async addItemById(...userIds: (string | BSON.ObjectID)[]): Promise<void> {
      const idsToSync = userIds
        .map((id) => id.toString())
        .filter((userId) => !Object.keys(this.itemsMap).includes(userId));

      if (idsToSync.length === 0) {
        return;
      }

      idsToSync.forEach((userId) => {
        this.itemsMap[userId] = null;
      });

      console.log('idsToSync', idsToSync);

      // Initial data
      const initialData = await realmService.getInstance().getUsersDetails(
        idsToSync
          .map((id) => {
            try {
              return new BSON.ObjectId(id);
            } catch (err) {
              console.error('Error al parsear el userId', { id }, err);
            }
          })
          .filter((id) => !!id) as BSON.ObjectId[]
      );

      initialData.forEach((user) => {
        // console.log('user', user._id.toString());
        this.itemsMap[user._id.toString()] = mapUserSchemaToData(user);
      });

      await this.restartObservable();
    },

    /**
     * Esto NO restablece la lista actual ya cargada, sólo el observable.
     */
    async restartObservable() {
      const idsToSync = Object.keys(this.itemsMap);

      if (this.itemsChangesSubscription) {
        this.itemsChangesSubscription.unsubscribe();

        this.itemsChanges$ = null;
      }

      try {
        const db = await realmService.getInstance().connect();

        const collection = db.collection<UsuariosSchema>('usuarios');

        const itemsChanges = collection.watch({
          ids: idsToSync,
        }) as AsyncGenerator<DocumentChangeEvent<UsuariosSchema>, any, unknown>;
        // FIXME: Controlar eventos sin documentos (DropEvent, RenameEvent, etc)
        // TODO: Controlar cuando a un usuario lo sacan del chat

        this.itemsChanges$ = from(itemsChanges).pipe(shareReplay(1));

        this.itemsChangesSubscription = this.itemsChanges$.subscribe((change) => {
          // console.log('user new', change);
          switch (change.operationType) {
            case 'insert':
              this.itemsMap[change.documentKey._id.toString()] = mapUserSchemaToData(change.fullDocument);

              break;

            case 'update':
              if (change.fullDocument) {
                this.itemsMap[change.documentKey._id.toString()] = mapUserSchemaToData(change.fullDocument);
              }

              break;

            case 'replace':
              this.itemsMap[change.documentKey._id.toString()] = mapUserSchemaToData(change.fullDocument);

              break;

            case 'delete':
              delete this.itemsMap[change.documentKey._id.toString()];

              break;
          }
        });
      } catch (err) {
        console.error('Error cargar los elementos', err);
      }
    },

    /**
     * Reinicia el estado del store.
     */
    clear(): void {
      this.userId = null;
      this.itemsMap = {};

      if (this.itemsChangesSubscription) {
        this.itemsChangesSubscription.unsubscribe();

        this.itemsChanges$ = null;
      }
    },
  },
});
