import { defineStore } from 'pinia';
import { BSON } from 'realm-web';

export interface ChatStatusData {
  draftContent?: string;
  mentionUsers?: Record<string, string>;
}

export const useChatStatusesDataStore = defineStore('chatStatusesData', {
  state: () => ({
    /**
     * Draft message index for chatId
     */
    itemsMap: {} as Record<string, ChatStatusData>,

    /**
     * Current authenticated user.
     */
    userId: null as string | null,
  }),

  getters: {
    /**
     * get non-null chat ids
     */
    ids(): string[] {
      return Object.entries(this.itemsMap)
        .filter(
          ([
            key,
            val,
          ]) => val !== null
        )
        .flatMap(
          ([
            key,
            val,
          ]) => key
        );
    },
  },

  actions: {
    init(userId: string | BSON.ObjectID | null) {
      if (!userId) {
        this.clear();
        return;
      }

      this.userId = userId.toString();
    },

    clear() {
      this.userId = null;
      this.itemsMap = {};
    },

    upsertItem(chatId: string, data: ChatStatusData) {
      const oldItem = this.getItem(chatId);
      this.itemsMap[chatId] = { ...oldItem, ...data };
    },

    removeItem(chatId: string): boolean {
      return delete this.itemsMap[chatId];
    },

    getItem(chatId: string): ChatStatusData | undefined {
      return this.itemsMap[chatId];
    },
  },
  persist: true,
});
