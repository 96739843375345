import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/getapps',
    component: () => import('@/layouts/GetappsLayout.vue'),
    name: 'getapps',
    children: [
      {
        path: '',
        name: 'getapps.index',
        component: () => import('@/pages/getapps/IndexPage.vue'),
      },
    ],
  },
  { path: '/:pathMatch(.*)*', redirect: '/getapps' },
];

export default routes;
