import { IParticipantData } from '@/models/schema/participant-schema';
import { groupBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { BSON } from 'realm-web';
import { Observable, Subscription, defer, from, retry, shareReplay } from 'rxjs';
import { IMessageData } from '../models/schema/message.data';
import { MessageSchema } from '../models/schema/message.schema';
import { DocumentChangeEvent, IPaginationOptions, realmService } from '../services/realm.service';
import { useChatsStore } from './chats.store';
import { useMessagesMediaStore } from './messages-media.store';
import { useUsersStore } from './users.store';

interface IUpdateResult {
  /**
   * IDs of items added.
   */
  added: string[];

  /**
   * IDs of items updated
   */
  updated: string[];
}

export interface IMessagesLoadOptions extends Omit<IPaginationOptions, 'page'> {
  /**
   * Indica que, si ya el chat tiene al menos 1 mensaje, ya no se haga una carga.
   *
   * Nota: Esta opción es la predeterminada cuando se carga un nuevo chat.
   */
  loadOnlyFirstMessages?: boolean;

  /**
   * Indica si se cargan todos los mensajes, desde el primer no leído. Es un alias para la paginación por cursor desde
   * ese mensaje, incluyendo algunos mensajes previos (15).
   */
  loadSinceFirstUnreadMessage?: boolean;
}

/**
 * Mantiene el estado de todos los mensajes de los chats.
 *
 * Mantiene un stream de la data de cada uno.
 */
export const useMessagesStore = defineStore('messages', {
  state: () => ({
    loadingMoreMessages: false,

    /**
     * Usuario autenticado que dará contexto a los mensajes leídos y demás getters.
     */
    userId: null as string | null,

    /**
     * Mensajes cargados, indexados por ID del chat al que pertenecen.
     * Los mensajes están ordenados por fecha de creación.
     *
     * Nota: Siempre usar `upsertMessages() u upsertMessagesFor()` para manipular este estado.
     */
    itemsMap: {} as Record<string, IMessageData[]>,

    /**
     * Stream con los cambios de los usuarios.
     */
    itemsChanges$: null as Observable<DocumentChangeEvent<MessageSchema>> | null,

    itemsChangesSubscription: null as Subscription | null,

    /**
     * Indica qué chats ya están recibiendo stream de mensajes.
     *
     * @internal
     */
    trackedChatIds: new Set<string>(),

    /**
     * Chats con posibles mensajes cargados pero del que ya no se deben obtener mensajes.
     * Todos los mensajes de estos chats son ignorados.
     */
    pausedChatIds: new Set<string>(),

    newMessageEventHandlers: [] as ((message: IMessageData) => void)[],

    /**
     * @deprecated Se va a eliminar para reemplazar con llamada directa desde el servicio.
     */
    markingAsSeen: false,

    /**
     * Timestamps con la última comprobación de mensajes.
     *
     * Estado temporal para usar como workaround para la des-conexión aleatoria de Realm en que deja de leer los nuevos
     * cambios: https://github.com/realm/realm-js/issues/6481
     */
    lastCheckForNewMessages: {} as Record<string, DateTime | undefined>,

    /**
     * Mapa, indexado por ID de chat, que indica si se están cargando sus mensajes iniciales (primera carga).
     */
    loadingInitialChatMessages: {} as Record<string, boolean>,

    /**
     * Mapa, indexado por ID de chat, que indica si se están cargando el último mensaje no leído, junto a algunos que
     * le rodean (7 anteriores y 7 siguientes), en cada chat.
     *
     * Esto podría más tiempo, ya que no se sabe la cantidad y, por tanto.
     */
    loadingFromFirstUnreadMessage: {} as Record<string, boolean>,

    /**
     * Mapa, indexado por ID de chat, que indica si se están cargando mensajes de manera indefinida en cada chat.
     *
     * Esto podría más tiempo, ya que no se sabe la cantidad y, por tanto, no se le pone límite (por ahora).
     */
    loadingUnlimitedOrLargeNumberOfChatMessages: {} as Record<string, boolean>,
  }),

  getters: {
    /**
     * IDs de los chats (`chatId`) no nulos (que sí se han inicializado sus mensajes).
     */
    ids(): string[] {
      return Object.entries(this.itemsMap)
        .filter(
          ([
            key,
            val,
          ]) => val !== null
        )
        .flatMap(
          ([
            key,
            val,
          ]) => key
        );
    },

    /**
     * Latest message of each chat (map by `chatId`).
     */
    latestMessage(): Record<string, IMessageData | null> {
      // FIXME: Mover esto a una propiedad computada en los componentes en la que se haga este proceso sólo con un chat, no con todos
      const messagesMap: Record<string, IMessageData | null> = {};

      Object.entries(this.itemsMap)
        .filter(
          ([
            chatId,
            messages,
          ]) => messages.filter((msg) => msg.contentType !== 'action').length > 0
        )
        .forEach(
          ([
            chatId,
            messages,
          ]) => {
            const noActionMessages = messages.filter((msg) => msg.contentType !== 'action');
            const lastMessage = noActionMessages[noActionMessages.length - 1];

            messagesMap[chatId] = lastMessage;
          }
        );

      return messagesMap;
    },

    messagesWithMedia(): IMessageData[] {
      return Object.entries(this.itemsMap)
        .filter(
          ([
            chatId,
            messages,
          ]) => messages.length > 0
        )
        .flatMap(
          ([
            chatId,
            messages,
          ]) => messages.filter((message) => !!message.mediaId)
        );
    },

    unreadMessagesCount(): Record<string, number> {
      const messagesMap: Record<string, number> = {};

      Object.keys(this.unreadMessages).forEach((chatId) => {
        messagesMap[chatId] = this.unreadMessages[chatId].length;
      });

      return messagesMap;
    },

    /**
     * Obtiene los mensajes sin leer del usuario actual.
     *
     * Para obtener el contador, se debe usar unreadMessagesCount, que da retrocompatibilidad con la implementación anterior.
     */
    unreadMessages(): Record<string, IMessageData[]> {
      const messagesMap: Record<string, IMessageData[]> = {};

      const userId = this.userId;

      if (!userId) {
        return messagesMap;
      }

      Object.entries(this.itemsMap)
        .filter(
          ([
            chatId,
            messages,
          ]) => messages.length > 0
        )
        .forEach(
          ([
            chatId,
            messages,
          ]) => {
            const unreadMessages = messages.filter((message) => {
              if (message.contentType === 'action') {
                return false;
              }

              if (message.senderId === userId) {
                return false;
              }

              return Object.keys(message.seenBy).length === 0
                ? // Para retro-compatibilidad, si seenBy está vacío
                  !message.seen
                : // Con la nueva data, si el usuario no está, significa que no está leído
                  !message.seenBy[userId];
            });

            messagesMap[chatId] = unreadMessages;
          }
        );

      return messagesMap;
    },

    messagesWithMentions(): IMessageData[] {
      const userId = this.userId;
      if (!userId) return [];
      const messagesWithMentions = Object.values(this.itemsMap)
        .flatMap((messages) => messages)
        .filter((message) => message.mentions && message.mentions.includes(userId));

      // console.log('Messages with mentions:', messagesWithMentions);
      return messagesWithMentions;
    },

    firstMentionedMessage(): Record<string, IMessageData | null> {
      // FIXME: Cambiar el método en el que reconoce mensajes leídos y no leídos incluyendo las menciones en el mensaje
      const messagesMap: Record<string, IMessageData | null> = {};
      const userId = this.userId;
      if (!userId) return messagesMap;

      Object.entries(this.itemsMap)
        .filter(
          ([
            chatId,
            messages,
          ]) => messages.some((msg) => msg.mentions && msg.mentions.includes(userId))
        )
        .forEach(
          ([
            chatId,
            messages,
          ]) => {
            const mentionMessages = messages.filter(
              (msg) => msg.mentions && msg.mentions.includes(userId) && !msg.seenBy[userId]
            );
            const firstMessageWithMention = mentionMessages[0];

            messagesMap[chatId] = firstMessageWithMention;
          }
        );

      return messagesMap;
    },

    unreadMessagesWithMentions(): IMessageData[] {
      const userId = this.userId;
      if (!userId) return [];
      const unreadMessages = this.messagesWithMentions.filter((message) => !message.seenBy || !message.seenBy[userId]);

      // console.log('Unread messages with mentions:', unreadMessages);
      return unreadMessages;
    },

    unreadMentionCount(): number {
      return this.unreadMessagesWithMentions.length;
    },

    /**
     * Cantidad de mensajes no leídos con menciones por chat.
     */

    messagesWithMentionsByChatCount(): Record<string, number> {
      const counts: Record<string, number> = {};
      const userId = this.userId;
      if (!userId) return counts;

      Object.entries(this.itemsMap).forEach(
        ([
          chatId,
          messages,
        ]) => {
          const unreadMentionsCount = messages.filter(
            (msg) => !msg.seenBy[userId] && msg.mentions?.includes(userId)
          ).length;
          counts[chatId] = unreadMentionsCount;
        }
      );

      return counts;
    },
  },

  actions: {
    init(userId: string | BSON.ObjectID | null) {
      const messagesMediaStore = useMessagesMediaStore();

      messagesMediaStore.init(userId);

      if (!userId) {
        this.clear();

        return;
      }

      // FIXME: Reemplazar al leer desde el auth store al usuario autenticado

      this.userId = userId.toString();
    },

    /**
     * Refresca todos los chats cargados en busca por mensajes perdidos. Carga 10 más.
     */
    async refreshMessages(): Promise<void> {
      const realm = realmService.getInstance();

      this.loadingMoreMessages = true;
      try {
        await Promise.all(
          // TODO: Agregar acá el refresh token si aún falla
          this.ids
            .filter((chatId) => {
              // No cargar mensajes de los chats pausados.
              return !this.pausedChatIds.has(chatId);
            })
            .map(async (chatId) => {
              const limit = (this.itemsMap[chatId]?.length ?? 0) + 10;

              try {
                const messages = (await realm.getChatMessages({ chatId }, { skip: 0, limit })).map(
                  IMessageData.normalizedFrom
                );

                this.upsertMessagesFor(chatId, ...messages);
              } catch (err) {
                console.error('refreshMessages', chatId, err);
              }
            })
        );
      } catch (err) {
        console.error('refreshMessages', err);
      } finally {
        this.loadingMoreMessages = false;
      }
    },

    /**
     * Carga mensajes más viejos de los chats especificados que aún no se hayan cargado.
     *
     * Útil para hacer scrolls con carga parcial.
     */
    async loadMoreMessagesFor(chatIds: string[], loadOptions: IMessagesLoadOptions = {}): Promise<void> {
      const userId = this.userId;

      if (!userId) {
        return;
      }

      // console.log('🆎 loadMoreMessagesFor', loadOptions, chatIds);
      // console.log(`loadMoreMessagesFor(${chatIds.join(', ')})`);

      // TODO: Implementar un loading state que identifique qué chat está cargando más mensajes

      // Sólo los que no están cargados. Se asume que los demás ya tienen un observer
      const notTrackedChatIds = [...new Set(chatIds.map((id) => id.toString()))].filter(
        (chatId) => !this.trackedChatIds.has(chatId) || !this.itemsMap[chatId]?.length
        // (chatId) => chatId === '666762acea98f4d6e4e876b9'
      );

      // console.log('🆎 loadMoreMessagesFor', notTrackedChatIds);

      notTrackedChatIds.forEach((chatId) => {
        // Agregar los chats que no están siendo observados a la lista para que sea usado por el observable.
        this.trackedChatIds.add(chatId);

        // Inicializa in [] el map
        this.upsertMessagesFor(chatId);
      });

      // TODO: Comprobar la posibilidad de que mientras se obtienen estos mensajes, ya se hayan escrito otros antes de iniciar el observable.
      // ¿Debe reiniciarse el observable antes para que, mientras se cargan mensajes viejos, no queden por fuera los nuevos?
      await this.restartObservable();

      // Si es más de uno, debe hacerse 1 request por cada uno para que se traiga al menos uno de cada.
      const realm = realmService.getInstance();

      this.loadingMoreMessages = true;

      try {
        await Promise.all(
          // TODO: Agregar acá el refresh token si aún falla
          chatIds
            .filter((chatId) => {
              // No cargar mensajes de los chats pausados.
              return !this.pausedChatIds.has(chatId);
            })
            .map(async (chatId) => {
              const options = { ...loadOptions };

              // Detectar cuantos de ese hay actualmente
              if (options.loadOnlyFirstMessages && (this.itemsMap[chatId] ?? []).length > 1) {
                return;
              }

              if (loadOptions.loadOnlyFirstMessages) {
                this.loadingInitialChatMessages[chatId] = true;
              }

              if (options.since || options.after || options.before || options.until) {
                this.loadingUnlimitedOrLargeNumberOfChatMessages[chatId] = true;
              }

              let skip = options.skip ?? this.itemsMap[chatId]?.length ?? 0;

              // if (chatId === '666762acea98f4d6e4e876b9') {
              //   console.log('🅰️ loadMoreMessagesFor', chatId, options);
              // }

              /**
               * Mensajes a ser insertados
               */
              const messages: IMessageData[] = [];

              try {
                const chatsStore = useChatsStore();

                let chat = chatsStore.items.find((c) => c.id === chatId);

                let participation: IParticipantData | undefined = undefined;

                if (chat) {
                  participation = chat.participants.find((c) => c.userId === userId);
                }

                if (options.loadSinceFirstUnreadMessage) {
                  // console.log(`loadMoreMessagesFor(${chatId})`, { createdAt: chat?.createdAt });

                  const firstUnreadResult = await realm.getFirstUnreadChatMessage(
                    chatId,
                    userId,
                    participation?.createdAt ?? undefined
                  );

                  if (!!firstUnreadResult) {
                    const surroundedMessagesLimit = 7;

                    // Inicia la carga de los elementos circundantes
                    this.loadingFromFirstUnreadMessage[chatId] = true;

                    // Se hace una llamada recursiva para los anteriores 7
                    await this.loadMoreMessagesFor([chatId], {
                      loadOnlyFirstMessages: false,
                      loadSinceFirstUnreadMessage: false,
                      skip: 0,
                      before: firstUnreadResult._id.toString(),
                      limit: surroundedMessagesLimit,
                    });

                    // Se inserta de forma ordenada el primer no leído
                    this.upsertMessagesFor(chatId, IMessageData.normalizedFrom(firstUnreadResult));

                    // messages.push(IMessageData.normalizedFrom(firstUnreadResult));

                    // // Se hace una llamada recursiva para los siguientes 7
                    await this.loadMoreMessagesFor([chatId], {
                      loadOnlyFirstMessages: false,
                      loadSinceFirstUnreadMessage: false,
                      skip: 0,
                      after: firstUnreadResult._id.toString(),
                      limit: surroundedMessagesLimit,
                    });

                    // Finaliza la carga de los elementos circundantes
                    this.loadingFromFirstUnreadMessage[chatId] = false;

                    // Abajo, se cargan los siguientes
                    options.after = firstUnreadResult._id.toString();

                    // Traerse todos los siguientes
                    skip = surroundedMessagesLimit;

                    // Inserta el actual

                    // FIXME: Ajustar luego si se cargan sólo cierta cantidad, ya que al no tener límite, puede cargar una cantidad demasiado grande y bloquear la aplicación.
                    delete options.limit;
                  } else {
                    if (!options.limit) {
                      // Si no se está paginando con cursor, se pone el límite a 10 por defecto
                      options.limit = 10;
                    }
                  }
                }

                // FIXME: Agregar un condicional para determinar la cantidad de mensajes desde el primer no leído para poder decidir si se hace una carga parcial cuando es la primera carga para mejorar el desempeño.

                // TODO: Probar si cargando inicialmente 10 y luego los demás, ayuda a desbloquear antes la vista sin necesidad del loader o poniendo uno menos intrusivo
                messages.push(
                  ...(
                    await realm.getChatMessages(
                      { chatId, afterDate: participation?.createdAt ?? undefined },
                      { ...options, skip }
                    )
                  ).map(IMessageData.normalizedFrom)
                );

                // if (chatId === '666762acea98f4d6e4e876b9') {
                //   console.log('🅱️ loadMoreMessagesFor', chatId, options, messages);
                // }

                this.upsertMessagesFor(chatId, ...messages);
              } catch (err) {
                console.error('loadMoreMessagesFor', chatId, err);
              } finally {
                if (loadOptions.loadOnlyFirstMessages) {
                  this.loadingInitialChatMessages[chatId] = false;
                }

                if (
                  loadOptions.loadSinceFirstUnreadMessage ||
                  !options.limit ||
                  options.since ||
                  options.after ||
                  options.before ||
                  options.until
                ) {
                  this.loadingUnlimitedOrLargeNumberOfChatMessages[chatId] = false;
                }
              }
            })
        );
      } catch (err) {
        console.error('loadMoreMessagesFor', err);
      } finally {
        this.loadingMoreMessages = false;
      }
    },

    /**
     * Reinicia la suscripción en tiempo real de los mensajes.
     *
     * Debe filtrarse antes si es necesario o no reiniciarlo, ya que no se comprueban esos estados.
     */
    async restartObservable() {
      // No suscribirse a los chats pausados
      const chatIdsToTrack = [...this.trackedChatIds];

      if (this.itemsChangesSubscription) {
        this.itemsChangesSubscription.unsubscribe();

        this.itemsChanges$ = null;
      }

      try {
        const realm = realmService.getInstance();
        const db = await realm.connect();

        const collection = db.collection<MessageSchema>('messages');

        const chatObjectIds: BSON.ObjectId[] = chatIdsToTrack.map((chatId) => new BSON.ObjectId(chatId));

        const itemsChanges = collection.watch({
          filter: {
            'fullDocument.chat': {
              // Sólo observa a los cambios de los chats activos
              $in: chatObjectIds,
            },
          },
        }) as AsyncGenerator<DocumentChangeEvent<MessageSchema>, any, unknown>;
        // FIXME: Controlar eventos sin documentos (DropEvent, RenameEvent, etc)
        // TODO: Controlar cuando a un usuario lo sacan del chat

        this.itemsChanges$ = from(itemsChanges).pipe(shareReplay(1));

        const chatsStore = useChatsStore();

        // Se guarda temporalmente la suscripción actual para no perder el hilo de los mensajes actuales
        let previousSubscription = this.itemsChangesSubscription;

        this.itemsChangesSubscription = this.itemsChanges$
          .pipe(
            retry({
              count: 5,
              delay: () => {
                return defer(() =>
                  realm.currentUser?.isLoggedIn ? realm.currentUser.refreshAccessToken() : realm.connect()
                );
              },
            })
          )
          .subscribe({
            next: (change) => {
              if (previousSubscription && !previousSubscription.closed) {
                previousSubscription.unsubscribe();
                previousSubscription = null;
              }

              // console.log('new message', change);

              // console.log('Message ', change.operationType, change.documentKey._id.toString()); // DEBUG

              // console.time(change.operationType + ' ' + change.documentKey._id.toString()); // DEBUG

              switch (change.operationType) {
                case 'insert':
                case 'update':
                case 'replace':
                  // console.log('Changed message', change.documentKey._id.toString(), change);
                  if (change.fullDocument) {
                    // console.log('Original seen', change.fullDocument.seen);

                    const message = IMessageData.normalizedFrom(change.fullDocument);

                    if (message.contentType === 'action' && message.actionData?.data.userId === this.userId) {
                      if (message.actionData?.actionType === 'deleteParticipant') {
                        this.pauseMessagesFrom(message.chatId);
                        chatsStore.removeItem(message.chatId);
                      }

                      if (message.actionData?.actionType === 'addParticipant') {
                        // TODO: Check async resumeMessagesFrom
                        this.resumeMessagesFrom(message.chatId);
                      }
                    }

                    if (this.pausedChatIds.has(message.chatId)) {
                      console.warn('Skipped because of chat paused', message.chatId); // DEBUG: Eliminar al completar la depuración

                      break;
                    }

                    // DEBUG: Si algo en el parseo se pierda
                    if (message.seen !== change.fullDocument.seen) {
                      console.error('seen is not being parsed well', {
                        original: change.fullDocument,
                        parsed: message,
                      });
                    }

                    if (message.replyTo && !message.replyToDetail) {
                      collection
                        .findOne({
                          _id: new BSON.ObjectId(message.replyTo),
                        })
                        .then((replyToDetail) => {
                          if (replyToDetail) {
                            message.replyToDetail = IMessageData.normalizedFrom(replyToDetail);
                          }
                        })
                        .finally(() => {
                          this.upsertMessages(message);
                          if (change.operationType === 'insert') {
                            this.emitNewMessageEvent(message);
                          }
                        });
                    } else {
                      this.upsertMessages(message);

                      if (change.operationType === 'insert') {
                        this.emitNewMessageEvent(message);
                      }
                    }
                  } else {
                    console.warn('Message without fullDocument and ignored', change.documentKey._id.toString());
                  }

                  break;

                case 'delete':
                  this.removeMessagesById(change.documentKey._id.toString());

                  break;
              }

              // console.timeEnd(change.operationType + ' ' + change.documentKey._id.toString()); // DEBUG
            },

            error: (err) => {
              console.error('messages$', err);
            },
            complete: () => {
              console.log('messages$ completed');
            },
          });
      } catch (err) {
        console.error('Error cargar los elementos', err);
      }
    },

    /**
     * Agrega uno o varios chats a la lista. Si no tiene mensajes, se cargarán al menos los últimos 10.
     *
     * @param chatIds
     * @returns
     */
    async addChat(...chatIds: (string | BSON.ObjectID)[]): Promise<void> {
      await this.loadMoreMessagesFor(
        chatIds.map((id) => id.toString()), // .filter((id) => id === '666762acea98f4d6e4e876b9'),
        {
          // Sólo cargar los últimos 10 mensajes de cada chat de forma inicial.
          limit: 10,
          loadOnlyFirstMessages: true,
          loadSinceFirstUnreadMessage: true,
        }
      );
    },

    /**
     * Elimina la suscripción a los mensajes del chat especificado.
     *
     * Puede llamarse cuando el chat cambia de participantes.
     */
    pauseMessagesFrom(...chatIds: string[] | BSON.ObjectId[]): void {
      const ids = [...new Set(chatIds.map((id) => id.toString()))];

      if (ids.length === 0) {
        return;
      }

      // // Comprueba si hay cambios sustanciales en los IDs de chats
      // // Si ya están todos, es porque ya están siendo considerados por el observable
      // let needToRestartObservable = ids.every((chatId) => this.pausedChatIds.has(chatId));

      // console.log('pauseMessagesFrom', { ids, needToRestartObservable });

      // if (!needToRestartObservable) {
      //   return;
      // }

      ids.forEach((chatId) => {
        this.pausedChatIds.add(chatId.toString());
      });

      // Pausar chats no deben reiniciar el observable, ya que se ignoran dentro de éste en el observer
      // await this.restartObservable();
    },

    /**
     * Vuelve a suscribirse a los mensajes del chat especificado.
     *
     * Puede llamarse cuando el chat cambia de participantes.
     */
    async resumeMessagesFrom(...chatIds: string[] | BSON.ObjectId[]): Promise<void> {
      const ids = [...new Set(chatIds.map((id) => id.toString()))];

      if (ids.length === 0) {
        return;
      }

      // Comprueba si hay algún id faltante
      let needToRestartObservable = ids.some((chatId) => !this.trackedChatIds.has(chatId));

      console.log('resumeMessagesFrom', { ids, needToRestartObservable });

      ids.forEach((chatId) => {
        // Por si acaso no estaba, se agrega
        this.trackedChatIds.add(chatId);
        this.pausedChatIds.delete(chatId);
      });

      if (!needToRestartObservable) {
        return;
      }

      await this.restartObservable();
    },

    /**
     * Inserta o actualiza los mensajes en su respectivo chat.
     */
    upsertMessages(...messages: IMessageData[]): IUpdateResult {
      const added = [] as string[];
      const updated = [] as string[];

      const groupedByChat = groupBy(messages, 'chatId');

      Object.keys(groupedByChat).forEach((chatId) => {
        const results = this.upsertMessagesFor(chatId, ...groupedByChat[chatId]);

        added.push(...results.added);
        updated.push(...results.updated);
      });

      return {
        added,
        updated,
      };
    },

    /**
     * Agrega o actualiza mensajes en el chat que corresponde.
     *
     * Nota: TODAS las operaciones para manipular los mensajes debe usar esta función u upsertMessages para
     * mantener consistencia.
     */
    upsertMessagesFor(chatId: string, ...messages: IMessageData[]): IUpdateResult {
      this.lastCheckForNewMessages[chatId] = DateTime.now();

      if (!Object.keys(this.itemsMap).includes(chatId)) {
        this.itemsMap[chatId] = [];
      }

      if (messages.length === 0) {
        return { added: [], updated: [] };
      }

      if (messages.some((message) => message.chatId !== chatId)) {
        throw new Error('All messages must belong to the same chat (`upsertMessages()` already does that for you :D).');
      }

      // Se pre-ordenan para evitar al mínimo las fluctuaciones en el DOM mientras se están operando
      messages.sort((a, b) => (a.createdAt?.valueOf() ?? 0) - (b.createdAt?.valueOf() ?? 0));

      const itemsToAdd: IMessageData[] = [];

      const added: string[] = [];
      const updated: string[] = [];

      const usersStore = useUsersStore();

      // Para actualizar, hay que asignar el array en una variable local. Si no, no detecta el cambio
      const currentMessages = this.itemsMap[chatId];

      messages.forEach((message) => {
        // Se evalúa si debe agregarse o actualizarse

        // Aunque se pudiera hacer todo de una vez, se deben controlar los posibles duplicados
        // this.itemsMap[chatId].unshift(...messages);

        const indexOf = currentMessages.findIndex((msg) => msg.id === message.id);
        const oldMessage = currentMessages[indexOf] ?? undefined;

        if (!oldMessage) {
          // No existe, así que se marca para agregarse
          itemsToAdd.push(message);
          usersStore.addItemById(message.senderId);
          added.push(message.id);
        } else {
          // Actualizar el mensaje en el array de mensajes de chat.

          // Sebe efectuarse un merge profundo.
          // const mergedMessage = IMessageData.mergeWith(oldMessage, message);

          currentMessages.splice(indexOf, 1, message);

          updated.push(message.id);
        }
      });

      if (itemsToAdd.length > 0) {
        const messages = this.itemsMap[chatId];
        // Como estos no existen, se pueden agregar directamente al principio de la lista.
        messages.unshift(...itemsToAdd);
      }

      // Finalmente, se ordenan
      this.sort(chatId);

      // Se detectan los mensajes con archivos
      const mediaIds = this.itemsMap[chatId].filter((message) => !!message.mediaId).map((message) => message.mediaId!);

      if (mediaIds.length > 0) {
        const messagesMediaStore = useMessagesMediaStore();

        // Se cargan los archivos de manera asincrónica
        messagesMediaStore.addItemByMediaId(...mediaIds);
      }

      return { added, updated };
    },

    /**
     * Carga la data de los archivos de todos los mensajes que tengan uno.
     */
    async syncMediaForMessages() {
      const messagesMediaStore = useMessagesMediaStore();

      const mediaIds: string[] = this.messagesWithMedia.map((message) => message.mediaId!);

      if (mediaIds.length === 0) {
        return;
      }

      await messagesMediaStore.addItemByMediaId(...mediaIds);
    },

    removeMessagesById(...messageIds: string[]): void {
      // FIXME: Implementar
      throw new Error('Not implemented');
    },

    /**
     * Ordena los mensajes de un chat por fecha de creación.
     */
    sort(chatId: string): void {
      if (this.itemsMap[chatId]) {
        this.itemsMap[chatId].sort((a, b) => (a.createdAt?.valueOf() ?? 0) - (b.createdAt?.valueOf() ?? 0));
      }
    },

    /**
     * Reinicia el estado del store.
     */
    clear(): void {
      this.userId = null;
      this.itemsMap = {};
      this.trackedChatIds.clear();
      this.pausedChatIds.clear();

      if (this.itemsChangesSubscription) {
        this.itemsChangesSubscription.unsubscribe();

        this.itemsChanges$ = null;
      }
    },

    emitNewMessageEvent(message: IMessageData) {
      // Implementación rudimentaria
      // TODO: Cambiar por los eventos nativos u otra manera
      this.newMessageEventHandlers.forEach((handler) => handler(message));
    },

    /**
     * Registra un manejador de eventos de nuevo mensaje.
     */
    onNewMessage(handler: (message: IMessageData) => void) {
      this.newMessageEventHandlers.push(handler);
    },

    offNewMessage() {
      this.newMessageEventHandlers = [];
    },
  },
});
