import { AxiosResponse } from 'axios';
import { ApiResponse } from './api-response';
import { mainApi } from './axios';

export enum VERBO_HTTP {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

/**
 * Obtiene la URL completa de la ruta especificada. Si ya es una URL completa, la revuelve tal y como es.
 */
export const fullUrl = (path?: string) => {
  const baseUrl = import.meta.env.VITE_API_BASE_URL;

  path = path?.trim();

  if (!path) {
    return baseUrl;
  }

  if (path.startsWith('http')) {
    return path;
  }

  // Quitar '/'s extra
  path = path
    .split('/')
    .filter((part) => !!part)
    .join('/');

  return `${baseUrl}/${path}`;
};

export class HttpService {
  static GetUrl() {
    return mainApi.getUri();
  }

  /**
   * @deprecated use fullUrl() function instead.
   */
  static GetFileUrl() {
    let uri = mainApi.getUri();
    return uri.substring(0, uri.length - 6);
  }

  static async Get<T>(url: string) {
    let response: T | undefined;
    try {
      let { data } = await mainApi({
        url: url,
        method: 'GET',
      });
      response = data;
    } catch (e) {}
    return response;
  }
  static async GetBlob(url: string) {
    let response: Blob | undefined;
    try {
      let { data } = await mainApi({
        url: url,
        method: 'GET',
        responseType: 'blob',
      });
      response = data;
    } catch (e) {}
    return response;
  }
  static async Post<T, T1>(request: T, url: string) {
    let resp: T1 | undefined;
    try {
      let data = await mainApi({
        method: 'POST',
        url: url,
        data: request,
      });
      resp = data?.data ?? data;
    } catch (e: any) {
      let { response } = e;
      resp = response.data;
    }
    return resp;
  }

  /**
   * Same as `Post`, without inline error handler.
   *
   * @param url
   * @param data
   * @returns
   */
  static async post<TData, TResponse>(url: string, formData: TData): Promise<TResponse> {
    const { data } = await mainApi<unknown, AxiosResponse<TResponse>>({
      method: 'POST',
      url,
      data: formData,
    });

    return data;
  }

  static async patch<TData, TResponse>(url: string, formData: TData): Promise<TResponse> {
    // FIXME: respuesta de axios es inconpleta en caso de error
    const response = await mainApi<unknown, AxiosResponse<TResponse>>({
      method: 'PATCH',
      url,
      data: formData,
    });

    return response.data ?? (response as TResponse);
  }

  static async put<TData, TResponse>(url: string, formData?: TData) {
    const { data } = await mainApi<unknown, AxiosResponse<TResponse>>({
      method: 'PUT',
      url,
      data: formData,
    });

    return data;
  }

  static async delete(url: string): Promise<AxiosResponse<ApiResponse<{}>>> {
    return await mainApi<unknown, AxiosResponse<ApiResponse<{}>>>({
      method: 'DELETE',
      url: url,
    });
  }

  static async Delete<T>(url: string) {
    let resp: T | undefined;
    try {
      let { data } = await mainApi({
        method: 'DELETE',
        url: url,
      });

      resp = data;
    } catch (e) {}
    return resp;
  }
  static async PostFormData<T, T1>(request: T, url: string, verbo?: VERBO_HTTP) {
    let resp: T1 | undefined;
    try {
      let data = await mainApi({
        method: verbo,
        url: url,
        data: request,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (data.status == 200) resp = data.data;
      else {
        //@ts-ignore
        resp = data;
      }
    } catch (e: any) {
      let { response } = e;
      resp = response.data;
    }
    return resp;
  }
  static async GetFromQuery<T, T1>(url: string, request: T1) {
    let response: T | undefined;
    try {
      let { data } = await mainApi({
        url: url,
        method: 'GET',
        params: request,
      });
      response = data;
    } catch (e) {}
    return response;
  }

  /**
   * @deprecated Use put() instead
   */
  static async PUT<T, T1>(request: T, url: string) {
    let resp: T1 | undefined;
    try {
      let { data } = await mainApi({
        method: 'PUT',
        url: url,
        data: request,
      });

      resp = data;
    } catch (e: any) {
      let { response } = e;
      resp = response.data;
    }
    return resp;
  }

  /**
   * @deprecated Use patch() instead
   */
  static async PATCH<T, T1>(request: T, url: string) {
    let resp: T1 | undefined;
    try {
      let { data } = await mainApi({
        method: 'PATCH',
        url: url,
        data: request,
      });

      resp = data;
    } catch (e: any) {
      let { response } = e;
      resp = response.data;
    }
    return resp;
  }
}
