import 'vuetify/styles';
// import { md3 } from 'vuetify/blueprints'
import { createVuetify } from 'vuetify';
import { aliases, fa } from 'vuetify/iconsets/fa-svg';
import colors from 'vuetify/util/colors';

// Styles

export interface BaseColors {
  background: string;
  surface: string;
  primary: string;
  secondary: string;
  success: string;
  warning: string;
  error: string;
  info: string;

  light: string;
  dark: string;

  'signature-layout-border-color': string;
  'signature-border-color': string;

  'starting-primary-gradient': string;
  'ending-primary-gradient': string;

  'starting-secondary-gradient': string;
  'ending-secondary-gradient': string;
}

export interface OnColors {
  'on-background': string;
  'on-surface': string;
  'on-primary': string;
  'on-secondary': string;
  'on-success': string;
  'on-warning': string;
  'on-error': string;
  'on-info': string;

  'on-light': string;
  'on-dark': string;
}

export interface Colors extends BaseColors, OnColors {
  [key: string]: string;
}

export interface InternalThemeDefinition {
  dark: boolean;
  colors: Colors;
  variables: Record<string, string | number>;
}

/**
 * Vuetify theme color name.
 */
export type ThemeColor = keyof OnColors | keyof BaseColors;

export default createVuetify({
  // blueprint: md3,
  theme: {
    defaultTheme: 'dark',
    themes: {
      dark: {
        colors: {
          background: '#000000',
          'on-background': '#FFFFFF',

          surface: '#23272C',
          'on-surface': '#8E8E93',

          // Colores semánticos
          danger: '#ED2222',
          warning: '#FFC73C',
          info: '#00B5FF',
          success: colors.green.accent2,

          // Colores de marca
          primary: '#00E3BB',
          secondary: '#6BFFD8',
          tertiary: '#22C4EC',

          // Colores de tono
          light: '#0B0A0A',
          dark: '#EEEEEE',

          gray: '#D9D9D9',
          // FIXME:  agrupar colores unas vez se defina la nueva paleta de colores
          'dark-gray': '#676767',
          'signature-layout-border-color': '#203e4b',
          'signature-border-color': '#676767',
          'guide-line': colors.red.accent2,
        },
      },
      light: {
        dark: false,
        colors: {
          background: '#FFFFFF',
          'on-background': '#000000',

          surface: '#23272C',
          'on-surface': '#8E8E93',

          // Colores semánticos
          danger: '#ED2222',
          warning: '#FFC73C',
          info: '#00B5FF',
          success: colors.green.accent2,

          // Colores de marca
          primary: '#6752E2',
          secondary: '#6BFFD8',
          tertiary: '#22C4EC',

          gray: '#979797',

          'starting-primary-gradient': '#F0F0F0',
          'ending-primary-gradient': '#C6D1E5',

          'starting-secondary-gradient': '#FFFFFF',
          'ending-secondary-gradient': '#FFFFFF',
        },
        variables: {
          'starting-primary-gradient-opacity': 1,
          'ending-primary-gradient-opacity': 1,
          'primary-gradient-degrees': '180deg',

          'starting-secondary-gradient-opacity': 0.5,
          'ending-secondary-gradient-opacity': 0.175,
          'secondary-gradient-degrees': '181.35deg',
        },
      },
    },
  },

  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    },
  },
});

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
