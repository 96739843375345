import { useAuthenticationStore } from '@/stores/authentication.store';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { IFailedApiResponse, ISuccessApiResponse } from './api-response';
// import { useLoaderStore } from '../stores/core/loading-store';

// const loaderStore = useLoaderStore();
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
    $api: AxiosInstance;
  }
}

export interface ISuccessResponse<TBody = unknown> {
  data: ISuccessApiResponse<TBody>;
  statusCode: 200 | 201 | 204 | 206 | 207 | 208 | 226;
}

export interface IErrorResponse<TBody = unknown> {
  data: IFailedApiResponse<TBody>;
  statusCode: 400 | 401 | 403 | 404 | 422 | 500 | 501 | 502 | 503 | 504;
}

export const isSuccessful = <TSuccessBody = unknown, TFailedBody = unknown>(
  response: ISuccessResponse<TSuccessBody> | IErrorResponse<TFailedBody>
): response is ISuccessResponse<TSuccessBody> => response.statusCode >= 200 && response.statusCode < 400;

const baseUrl: string = `${import.meta.env.VITE_API_BASE_URL}/api/v1`;

/**
 * @deprecated Use newApi instead.
 */
const mainApi = axios.create({ baseURL: baseUrl });

mainApi.interceptors.request.use((req) => {
  const auth2 = useAuthenticationStore();

  if (auth2.token) {
    req.headers.Authorization = `Bearer ${auth2.token}`;
  }

  return req;
});

mainApi.interceptors.response.use(
  async (resp) => {
    return resp;
  },
  (error: AxiosError) => {
    if (error) {
      // FIXME: corregir tener el mismo formato de la repuesta positiva
      return error.response?.data ?? { ok: false, msg: error.response?.data };
    }
  }
);

/**
 * Main API for the application, with the authorization header.
 *
 * Note: It does not intercepts errors.
 *
 * @deprecated Use v2 instead.
 */
const v1 = axios.create({ baseURL: baseUrl });

v1.interceptors.request.use((req) => {
  const auth2 = useAuthenticationStore();

  if (auth2.token) {
    req.headers.Authorization = `Bearer ${auth2.token}`;
  }

  return req;
});

/**
 * HTTP Client for the application.
 *
 * Base URL: /api
 */
const httpApiClient = axios.create({ baseURL: `${import.meta.env.VITE_API_BASE_URL}/api` });

httpApiClient.interceptors.request.use((req) => {
  const auth2 = useAuthenticationStore();

  if (auth2.token) {
    req.headers.Authorization = `Bearer ${auth2.token}`;
  }

  return req;
});

httpApiClient.defaults.withCredentials = false;

httpApiClient.defaults.headers.common['Content-Type'] = 'application/json';
httpApiClient.defaults.headers.common['Accept'] = 'application/json';
httpApiClient.defaults.headers.common['X-App-Company-Id'] = import.meta.env.VITE_APP_COMPANY_ID;

/**
 * Tries an axios request, normalizing error / success response.
 *
 * @throws Any non-AxiosError exception.
 */
export const tryRequest = async <TBody = unknown, TErrorBody = unknown>(
  req: Promise<AxiosResponse<TBody>>,
  successDataParser?: (resp: AxiosResponse) => ISuccessApiResponse<TBody>
): Promise<[IErrorResponse<TErrorBody | null>, undefined] | [undefined, ISuccessResponse<TBody>]> => {
  try {
    const result = await req;

    let data: ISuccessApiResponse<TBody>;

    if (successDataParser) {
      data = successDataParser(result);
    } else {
      data = ISuccessApiResponse.is<TBody>(result.data) ? result.data : { ok: true, body: result.data, msg: 'Ok' };
    }

    return [
      undefined,
      {
        statusCode: result.status as 200,
        data,
      },
    ];
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }

    const errorResponse = error.response?.data as any | undefined | null;

    let data: IFailedApiResponse<TErrorBody | null> = { ok: false, msg: 'KO', body: null };

    if (errorResponse) {
      data = IFailedApiResponse.is<TErrorBody | null>(errorResponse)
        ? errorResponse
        : { ok: false, msg: error.message, body: errorResponse };
    }

    return [
      {
        statusCode: (error.response?.status as 400) ?? 400,
        data,
      },
      undefined,
    ];
  }
};

export { httpApiClient, mainApi, v1 };
