import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { ulid } from 'ulid';

/**
 * Estados actuales de la ventana de la aplicación.
 *
 * TODO: Move to a service instead
 */
export const useWindowDocumentStore = defineStore('windowDocument', {
  state: () => ({
    /**
     * Indica si la ventana está activa. Si este valor es false, significa que el usuario está en otra pestaña.
     */
    visibilityState: 'visible' as DocumentVisibilityState,
    hiddenSince: DateTime.now(),
    visibleSince: DateTime.now(),

    visibleHandlers: [] as { id: string; handle: (since: DateTime) => void }[],
    hiddenHandlers: [] as { id: string; handle: (since: DateTime) => void }[],
  }),

  getters: {},

  actions: {
    init(): void {
      document.addEventListener('visibilitychange', (event: Event) => {
        const visibilityState = (event.target as Document).visibilityState;

        if (visibilityState === 'hidden') {
          this.hiddenSince = DateTime.now();

          this.hiddenHandlers.forEach((sub) => {
            sub.handle(this.visibleSince);
          });
        }

        if (visibilityState === 'visible') {
          this.visibleSince = DateTime.now();

          this.visibleHandlers.forEach((sub) => {
            sub.handle(this.hiddenSince);
          });
        }

        this.visibilityState = visibilityState;
      });
    },

    /**
     * Se subscribe al evento de mostrar la aplicación en la pestaña del navegador.
     */
    onVisible(handler: (hiddenSince: DateTime) => void): { unsubscribe: () => void } {
      const eventId = ulid();

      const subscription = {
        unsubscribe: () => {
          this.visibleHandlers = this.visibleHandlers.filter((s) => s.id !== eventId);
        },
      };

      this.visibleHandlers.push({ handle: handler, id: eventId });

      return subscription;
    },

    /**
     * Se subscribe a los eventos de ocultar la ventana.
     */
    onHidden(handler: (visibleSince: DateTime) => void): { unsubscribe: () => void } {
      const eventId = ulid();

      const subscription = {
        unsubscribe: () => {
          this.hiddenHandlers = this.hiddenHandlers.filter((s) => s.id !== eventId);
        },
      };

      this.hiddenHandlers.push({ handle: handler, id: eventId });

      return subscription;
    },
  },
});
