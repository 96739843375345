<template>
  <div id="c-base-card" class="base-card">
    <v-card
      :variant="variant"
      :class="cardClass"
      :width="width"
      :min-width="minWidth"
      :max-width="maxWidth"
      :height="height"
      :color="color"
      :style="cardStyle"
    >
      <slot />
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { type PropType, computed } from 'vue';

// TODO: check how to import variant types from vuetify
type Variant = 'flat' | 'text' | 'elevated' | 'tonal' | 'outlined' | 'plain';

const props = defineProps({
  customClass: {
    type: [
      String,
      Array,
    ],
    default: '',
  },
  width: {
    type: [
      String,
      Number,
    ],
    default: '100%',
  },
  minWidth: { type: String, default: '' },
  maxWidth: { type: String, default: '' },
  height: {
    type: [
      String,
      Number,
    ],
    default: '100%',
  },
  color: { type: String, default: 'black' },
  variant: { type: String as PropType<Variant>, default: 'elevated' },
  hideBorder: { type: Boolean, default: false },
  borderColor: { type: String, default: '' },
});

const cardClass = computed(() => {
  let customClass = 'mx-auto';

  if (props.customClass) {
    customClass += ` ${props.customClass}`;
  }

  if (!props.hideBorder) {
    customClass += ' base-card__custom';
  }

  return customClass;
});
const cardStyle = computed(() => {
  let style = '';

  if (props.hideBorder && props.borderColor) {
    style += `border: 1px solid ${props.borderColor} !important;`;
  }

  return style;
});
</script>

<style lang="scss" scoped>
.base-card {
  &__custom {
    border: 1px solid rgb(var(--v-theme-signature-border-color)) !important;
  }
}
</style>
