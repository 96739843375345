import { createApp } from 'vue';
import VueKonva from 'vue-konva';
import VuePdf from 'vue3-pdfjs';

import App from './App.vue';
import AppLink from './components/core/AppLink.vue';
import GlobalSpinnerVue from './components/core/GlobalSpinner.vue';
import { httpApiClient } from './core/axios';
import { installFontAwesome } from './plugins/font-awesome';
import markdownIt from './plugins/markdown-it';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import { AuthenticationServiceProvider } from './providers/authentication.service-provider';
import router from './router';
import guestRouter from './router/index-guest';
import pinia from './stores';
import { useCurrentCompanyStore } from './stores/current-company.store';
import { useWindowDocumentStore } from './stores/window-document.store';
import { isMobile } from './utils/helpers';

// global components
import BaseButton from '@/components/base-components/BaseButton.vue';
import BaseCard from '@/components/base-components/BaseCard.vue';
import ActionPicker from '@/components/general/ActionPicker.vue';
import Loader from '@/components/general/Loader.vue';

import './analytics/track-js';

loadFonts();
const app = createApp(App);

app.component('GlobalSpinner', GlobalSpinnerVue);
app.component('app-link', AppLink);
app.component('BaseCard', BaseCard);
app.component('BaseButton', BaseButton);
app.component('ActionPicker', ActionPicker);
app.component('Loader', Loader);

installFontAwesome(app);

const activeRouter = isMobile() ? guestRouter : router;

app.use(AuthenticationServiceProvider.plugin, { service: new AuthenticationServiceProvider(httpApiClient) });

app
  //
  .use(vuetify)
  .use(activeRouter)
  .use(pinia)
  .use(markdownIt)
  .use(VuePdf)
  .use(VueKonva);

app.mount('#app');

const win = useWindowDocumentStore();

win.init();

const currentCompanyStore = useCurrentCompanyStore();

try {
  currentCompanyStore.init().then(() => {
    // TODO: Eliminar # DEBUG
    console.log('Loaded company 🆔', currentCompanyStore.id, currentCompanyStore.data, currentCompanyStore.settings);
  });
} catch (error) {
  // console.error('Error loading company:', error);
}
//
