import { defineStore } from 'pinia';
import { BSON } from 'realm-web';
import { Observable, Subscription, from, shareReplay } from 'rxjs';
import { IMediaData } from '../models/schema/media.data';
import { MediaSchema } from '../models/schema/media.schema';
import { DocumentChangeEvent, realmService } from '../services/realm.service';

/**
 * Lista de uploads de los mensajes.
 *
 * Mantiene un stream de la data de cada uno.
 */
export const useMessagesMediaStore = defineStore('messagesMedia', {
  state: () => ({
    /**
     * Usuario autenticado.
     */
    currentUserId: null as string | null,

    /**
     * Usuarios cargados indexados por su ID.
     */
    itemsMap: {} as Record<string, IMediaData | null>, // Corregir normalización como en los chats

    /**
     * Stream con los cambios de los usuarios.
     */
    itemsChanges$: null as Observable<DocumentChangeEvent<MediaSchema>> | null,

    itemsChangesSubscription: null as Subscription | null,
  }),

  getters: {
    /**
     * IDs de documentos no nulos (que sí tienen data cargada).
     */
    ids(): string[] {
      return Object.entries(this.itemsMap)
        .filter(
          ([
            key,
            val,
          ]) => val !== null
        )
        .flatMap(
          ([
            key,
            val,
          ]) => key
        );
    },

    // TODO: revisar uno por status
  },

  actions: {
    async init(currentUserId: string | BSON.ObjectID | null): Promise<void> {
      if (!currentUserId) {
        this.clear();

        return;
      }

      if (currentUserId.toString() === this.currentUserId) {
        if (this.itemsChanges$ && !!this.itemsChangesSubscription && !this.itemsChangesSubscription.closed) {
          // TODO: Add an option to force to reset the subscription
          return;
        }
      }
    },

    /**
     * Agrega un documento a la lista especificando el ID. Si no tiene data cargada, se incluirá.
     *
     * Se mantendrá un stream abierto con los cambios de ese documento.
     */
    async addItemByMediaId(...mediaIds: string[] | BSON.ObjectID[]): Promise<void> {
      const idsToSync = mediaIds
        .map((id) => id.toString())
        .filter((mediaId) => !Object.keys(this.itemsMap).includes(mediaId));

      if (idsToSync.length === 0) {
        return;
      }

      // Initial data
      const initialData = await realmService.getInstance().getMediaById(...idsToSync);

      initialData.map(IMediaData.normalizedFrom).forEach((media) => {
        this.itemsMap[media.id] = media;
      });

      await this.restartObservable();
    },

    async addItemByMessageId(...messageIds: string[] | BSON.ObjectID[]): Promise<void> {
      // TODO: Excluir los mensajes que ya están siendo rastreados
      if (messageIds.length === 0) {
        return;
      }

      // Initial data
      const response = await realmService.getInstance().getMediaByMessageId(...messageIds);

      response.map(IMediaData.normalizedFrom).forEach((media) => {
        this.itemsMap[media.id] = media;
      });

      await this.restartObservable();
    },

    /**
     * Esto NO restablece la lista actual ya cargada, sólo el observable.
     */
    async restartObservable() {
      // TODO: Almacenar el estado actual de suscripciones para evitar reiniciar innecesariamente
      const idsToSync = Object.keys(this.itemsMap);

      this.clearChangesObservable();

      try {
        const db = await realmService.getInstance().connect();

        const collection = db.collection<MediaSchema>(MediaSchema.COLLECTION_NAME);

        const itemsChanges = collection.watch({
          ids: idsToSync,
        }) as AsyncGenerator<DocumentChangeEvent<MediaSchema>, any, unknown>;

        this.itemsChanges$ = from(itemsChanges).pipe(shareReplay(1));

        this.itemsChangesSubscription = this.itemsChanges$.subscribe({
          next: (change) => {
            console.time('itemsChangesSubscription next');
            // console.log('user new', change);
            switch (change.operationType) {
              case 'insert':
              case 'replace':
              case 'update':
                if (change.fullDocument) {
                  this.itemsMap[change.documentKey._id.toString()] = IMediaData.normalizedFrom(change.fullDocument);
                }

                break;

              case 'delete':
                delete this.itemsMap[change.documentKey._id.toString()];

                break;
            }

            console.timeEnd('itemsChangesSubscription next');
          },
          error: (error) => {
            console.error('MessagesMediaStore ERROR', error);
          },
          complete: () => {
            console.log('MessagesMediaStore COMPLETED');
          },
        });
      } catch (err) {
        console.error('Error cargar los elementos', err);
      }
    },

    /**
     * Reinicia el estado del store.
     */
    clear(): void {
      this.currentUserId = null;
      this.itemsMap = {};

      this.clearChangesObservable();
    },

    clearChangesObservable(): void {
      if (this.itemsChangesSubscription) {
        this.itemsChangesSubscription.unsubscribe();

        this.itemsChanges$ = null;
      }
    },
  },
});
