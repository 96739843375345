import { defaults, isEmpty, omitBy, pick } from 'lodash-es';
import { LocationQuery } from 'vue-router';

/**
 * Obtiene la data completa de un objeto parcial, con sus valores predeterminados.
 */
export const defaultedData = <T extends { [key: string]: any }>(partialData: Partial<T>, defaultData: T): T => {
  const safeData = omitBy(partialData, (key, val) => {
    return isEmpty(val);
  });

  const fullData = defaults(pick(safeData, Object.keys(defaultData)), defaultData);

  return fullData as T;
};

/**
 * Extrae los valores de la query string para las propiedades especificadas.
 * Si alguno está duplicado, obtiene el primero.
 */
export const extractFirstValueFromQueryParams = <TKey extends string>(
  query: LocationQuery,
  names: TKey[]
): Record<TKey, string | null> => {
  const map: Record<TKey, string | null> = {} as Record<TKey, string | null>;

  names.forEach((name) => {
    map[name] = (Array.isArray(query[name]) ? (query[name]?.[0] as string) : (query[name] as string | null)) ?? null;
  });

  return map;
};

/**
 * check if user is navigating from web or mobil browser.
 */
export const isMobile = (): boolean => {
  return !!window.navigator.userAgent.match(/(iPhone|iPod|iPad|Android)/);
};

/**
 * Gets an element that may not have been rendered
 */
export const waitHtmlElemnt = async (id: string): Promise<HTMLElement | null> => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      const element = document.getElementById(id);
      if (element) {
        clearInterval(interval);
        resolve(element);
      }
    }, 200);
  });
};
