import { ICompanySettingData } from '@/models/schema/company-setting.data';
import { CompanySettingKey, CompanySettingSchema, ICompanySettingsMap } from '@/models/schema/company-setting.schema';
import { ICompanyData } from '@/models/schema/company.data';
import { CompanySchema } from '@/models/schema/company.schema';
import { realmService } from '@/services/realm.service';
import { defineStore } from 'pinia';
import { BSON } from 'realm-web';
import { Ref, ref } from 'vue';

export const useCurrentCompanyStore = defineStore<
  'currentCompany',
  {
    /**
     * ID de la compañía actual.
     */
    id: Ref<string | null>;

    /**
     * Datos de la compañía.
     */
    data: Ref<ICompanyData | null>;

    /**
     * Configuraciones de la compañía.
     */
    settings: Ref<ICompanySettingsMap>;

    init: (companyId?: BSON.ObjectID | string) => Promise<void>;

    refreshData: () => Promise<void>;

    fetchSettings: () => Promise<void>;
  }
>(
  'currentCompany',
  () => {
    const id = ref<string | null>(import.meta.env.VITE_APP_COMPANY_ID || null);

    /**
     * Datos de la compañía.
     */
    const data = ref<ICompanyData | null>(null);

    const settings = ref(ICompanySettingsMap.defaulted());

    const fetchSettings = async () => {
      settings.value = ICompanySettingsMap.defaulted();

      if (!id.value) {
        return;
      }

      const db = await realmService.getInstance().connect();

      const fetchedSettings = await db
        .collection<CompanySettingSchema<CompanySettingKey>>(CompanySettingSchema.COLLECTION_NAME)
        .find({
          company: new BSON.ObjectId(id.value),

          // Sólo los keys compatibles
          key: { $in: Object.keys(ICompanySettingsMap.defaulted()) },
        });

      fetchedSettings
        .map((setting) => {
          // console.log('🆘 setting', setting);

          return ICompanySettingData.normalizedFrom(setting);
        })
        .forEach((setting) => {
          settings.value[setting.key] = setting.value;
        });
    };

    const refreshData = async () => {
      if (!id.value) {
        data.value = null;

        return;
      }

      const db = await realmService.getInstance().connect();

      const company = await db.collection<CompanySchema>(CompanySchema.COLLECTION_NAME).findOne({
        _id: new BSON.ObjectId(id.value),
      });

      if (!company) {
        data.value = null;

        console.error(`Company ${id.value} not found!`);

        return;
      }

      data.value = ICompanyData.normalizedFrom(company);

      // TODO: Inicializar los settings.
    };

    const init = async (companyId: BSON.ObjectID | string | null = import.meta.env.VITE_APP_COMPANY_ID) => {
      if (!companyId) {
        console.warn('Company is not set');

        data.value = null;
        id.value = null;
        settings.value = ICompanySettingsMap.defaulted();

        return;
      }

      const stringId = companyId.toString();

      // const isSameId = id.value === stringId;

      id.value = stringId;

      // if (isSameId && data.value !== null) {
      //   // Recarga la data de la organización, delegándola a un segundo plano, porque ya hay data almacenada localmente
      //   refreshData().then(() => {
      //     fetchSettings();
      //   });
      // } else {
      //   // O no hay data o el ID es distinto. Entonces se espera a cargar la data
      //   await Promise.all([
      //     refreshData(),
      //     fetchSettings(),
      //   ]);
      // }
    };

    return {
      // State

      id,
      data,
      settings,

      // Getters

      // Actions
      init,
      refreshData,
      fetchSettings,
    };
  },
  { persist: true }
);
