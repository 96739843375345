import { BSON } from 'realm-web';
import { SchemaBase } from './schema-base';

/**
 * Structure for `documents` document.
 */
export interface MediaSchema extends SchemaBase {
  name: string;
  storage_url: string;
  document_type: string;
  document_status: string;
  message: BSON.ObjectId;
}

export namespace MediaSchema {
  export const COLLECTION_NAME = 'documents';
}
