import { defaultedData } from '../../utils/helpers';
import { IBaseData } from '../utils/base-data';
import { CompanySchema } from './company.schema';

export interface ICompanyData extends IBaseData {
  name: string;
  active: boolean;
}

// Funciones para operar con IMessageData:
export namespace ICompanyData {
  /**
   * Obtiene un objeto IMessageData con valores por defecto establecidos.
   *
   * @param partialData Se usa para reemplazar los valores predeterminados (ignorando todas aquellas no definidas).
   */
  export const defaulted = (partialData: Partial<ICompanyData> = {}): ICompanyData => {
    const defaultData: ICompanyData = {
      ...IBaseData.defaulted(partialData),

      active: false,
      name: '',
    };

    return defaultedData(partialData, defaultData);
  };

  /**
   * Convierte un objeto MessageSchema parcial a IMessageData normalizado.
   */
  export const normalizedFrom = (partialData: Partial<CompanySchema> | Partial<ICompanyData>): ICompanyData => {
    // Auxiliar para poder operar como si fuera cualquiera de los 2 tipos
    const mergedPartialData = partialData as Partial<CompanySchema> & Partial<ICompanyData>;

    const dataFromSchema: Partial<ICompanyData> = {
      ...IBaseData.normalizedFrom(partialData),

      name: mergedPartialData.name,
      active:
        typeof mergedPartialData.active === 'boolean'
          ? mergedPartialData.active
          : mergedPartialData.status === 'active',
    };

    return defaulted(dataFromSchema);
  };

  // TODO: Agregar más métodos para operar con mensajes
}
