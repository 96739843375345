import { IUserData } from '@/models/schema/usuarios-schema';
import { AuthenticationServiceProvider, IOauth2TokenData } from '@/providers/authentication.service-provider';
import { useServiceProvider } from '@/support/service-provider';
import { useTimeoutPoll } from '@vueuse/core';
import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useAuthStore } from './auth-store';
import { useUsersStore } from './users.store';

/**
 * Store para manejar la autenticación.
 */
export const useAuthenticationStore = defineStore(
  'authentication',
  () => {
    const currentUserId = ref<string | null>(null);

    const tokenData = ref<
      | (IOauth2TokenData & {
          /**
           * Timestamp (mili-segundos) de la expiración del token.
           */
          expires_at: number;
        })
      | null
    >(null);

    /**
     * Access token.
     */
    const token = computed<string | null>(() => {
      return tokenData.value?.access_token ?? null;
    });

    const oAuth2Service = useServiceProvider(AuthenticationServiceProvider);

    const expiresAt = computed<DateTime | null>(() => {
      return tokenData.value?.expires_at ? DateTime.fromMillis(tokenData.value.expires_at) : null;
    });

    /**
     * Indica si el token de autenticación ha sido validado (el token puede estar generado, pero no necesariamente autorizado).
     *
     * Si es undefined
     */
    const hasAuthorizedToken = computed<boolean | undefined>(() => {
      if (!token.value) {
        return false;
      }

      // El userId sólo se establece cuando se hace el primer fetch de la data del usuario (syncTokenUser)
      if (!!token.value && !currentUserId.value) {
        return undefined; // Indica que debe llamarse a syncTokenUser()
      }

      return !!token.value && !!currentUserId.value;
    });

    const currentUser = computed<IUserData | null | undefined>(() => {
      if (!currentUserId.value) {
        return null;
      }

      const userStore = useUsersStore();

      return userStore.itemsMap[currentUserId.value] ?? undefined;
    });

    const clear = (): void => {
      currentUserId.value = null;
      tokenData.value = null;

      pauseRefreshTokenTimeoutPoll();

      const userStore = useUsersStore();
      const authStore = useAuthStore();

      userStore.init(currentUserId.value);

      authStore.logout();
    };

    /**
     * Fetch the user, validating the current token.
     */
    const syncTokenUser = async () => {
      if (!token.value) {
        currentUserId.value = null;

        return;
      }

      const [
        error,
        response,
      ] = await oAuth2Service.getAuthenticatedUser();

      const userStore = useUsersStore();

      if (error) {
        if (error.statusCode === 403) {
          currentUserId.value = null;

          userStore.init(null);
        }

        return error;
      }

      currentUserId.value = response?.data.body.id ?? null;

      await userStore.init(currentUserId.value, { force: true });

      return response;
    };

    const REFRESH_TOKEN_THRESHOLD_IN_SECONDS = 65;

    /**
     *
     * NOTA: No llamar nunca en el init, para evitar recursividad infinita.
     */
    const refreshToken = async (force: boolean = false) => {
      if (!tokenData.value?.refresh_token) {
        return;
      }

      if (
        !force &&
        (!expiresAt.value || expiresAt.value.diffNow('seconds').seconds > REFRESH_TOKEN_THRESHOLD_IN_SECONDS)
      ) {
        // no forced and null expiration or diff in seconds is less than threshold
        return;
      }

      const response = await oAuth2Service.signInUsingRefreshToken({ refreshToken: tokenData.value.refresh_token });

      // console.log('refreshToken', response);
      return response;
    };

    const {
      isActive: isRefreshTokenTimeoutPollActive,
      pause: pauseRefreshTokenTimeoutPoll,
      resume: resumeRefreshTokenTimeoutPoll,
    } = useTimeoutPoll(
      async () => {
        // console.log('refreshToken via useTimeoutPoll');
        await refreshToken();
        // console.log('refreshToken via useTimeoutPoll');
      },
      (REFRESH_TOKEN_THRESHOLD_IN_SECONDS - 5) * 1000,
      { immediate: true }
    );

    const init = async (
      data: { tokenData: IOauth2TokenData & { expires_at?: number } } | null,
      options: { force?: boolean } = {}
    ): Promise<void> => {
      if (!data) {
        clear();

        return;
      }

      resumeRefreshTokenTimeoutPoll();

      if (data.tokenData.access_token === token.value && !options.force) {
        return;
      }

      tokenData.value = {
        ...data.tokenData,
        expires_at: data.tokenData.expires_at ?? DateTime.now().plus({ seconds: data.tokenData.expires_in }).toMillis(),
      };
    };

    /**
     * Re-inicializa con los valores actuales.
     */
    const refresh = async (options: { force?: boolean } = {}): Promise<void> => {
      await init(tokenData.value ? { tokenData: tokenData.value } : null, options);

      await syncTokenUser();
    };

    return {
      // state
      currentUserId,
      tokenData,

      // Getters
      currentUser,
      token,
      expiresAt,
      hasAuthorizedToken,

      // Actions
      init,
      refresh,
      clear,
      refreshToken,
      syncTokenUser,

      isRefreshTokenTimeoutPollActive,
      pauseRefreshTokenTimeoutPoll,
      resumeRefreshTokenTimeoutPoll,
    };
  },

  {
    persist: {
      paths: [
        'currentUserId',
        'tokenData',
      ],
      afterRestore: (context) => {
        console.log('afterRestore', context.store);

        if (context.store.tokenData) {
          context.store.resumeRefreshTokenTimeoutPoll();
        }

        context.store.refreshToken(true).then(() => {
          context.store.syncTokenUser();
        });
      },
    },
  }
);
