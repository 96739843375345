import { SchemaBase } from './schema-base';

export type CompanyStatus = 'active' | 'inactive';

/**
 * Estructura en crudo desde la base de datos de la colección `companies`.
 */
export interface CompanySchema extends SchemaBase {
  name: string;
  status: CompanyStatus;
}

export namespace CompanySchema {
  export const COLLECTION_NAME = 'companies';
}
