import { isNil, omitBy } from 'lodash-es';
import { fullUrl } from '../../core/httpService.service';
import { defaultedData } from '../../utils/helpers';
import { IBaseData } from '../utils/base-data';
import { MediaSchema } from './media.schema';

export interface IMediaData extends IBaseData {
  messageId: string;
  name: string;

  /**
   * Ruta del storage en donde está almacenado el archivo.
   */
  path: string;

  /**
   * URL pública generada del archivo.
   */
  url: string;

  type: string;
}

// Funciones para operar con IMessageData:
export namespace IMediaData {
  /**
   * Obtiene un objeto IMessageData con valores por defecto establecidos.
   *
   * @param partialData Se usa para reemplazar los valores predeterminados (ignorando todas aquellas no definidas).
   */
  export const defaulted = (partialData: Partial<IMediaData> = {}): IMediaData => {
    const defaultData: IMediaData = {
      ...IBaseData.defaulted(partialData),

      messageId: '',
      name: '',
      path: '',
      url: '',
      type: '',
    };

    return defaultedData(partialData, defaultData);
  };

  /**
   * Convierte un objeto MediaSchema parcial a IMessageData normalizado.
   */
  export const normalizedFrom = (partialData: Partial<MediaSchema> | Partial<IMediaData>): IMediaData => {
    // Auxiliar para poder operar como si fuera cualquiera de los 2 tipos
    const mergedPartialData = partialData as Partial<MediaSchema>;

    const dataFromSchema: Partial<IMediaData> = {
      ...IBaseData.normalizedFrom(partialData),

      messageId: mergedPartialData.message?.toString(),
      name: mergedPartialData.name,
      path: mergedPartialData.storage_url,
      type: mergedPartialData.document_type,
    };

    if (dataFromSchema.path) {
      dataFromSchema.url = fullUrl(dataFromSchema.path);
    }

    const data = defaultedData(
      omitBy(dataFromSchema, isNil),

      // Extract if partialData is Partial<IMediaData>
      defaulted(partialData)
    );

    data.type = data.type.toLowerCase();

    return data;
  };
}
