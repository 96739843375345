import { DateTime } from 'luxon';
import { defineStore } from 'pinia';
import { BSON } from 'realm-web';
import { Observable, Subscription, defer, from, retry, shareReplay } from 'rxjs';
import { ChatSchema, IChatData, mapChatSchemaToData } from '../models/schema/chats-schema';
import { IParticipantData } from '../models/schema/participant-schema';
import { IUserData } from '../models/schema/usuarios-schema';
import { IDataWithId } from '../models/utils/base-data';
import { DocumentChangeEvent, realmService } from '../services/realm.service';
import { useChatStatusesDataStore } from './chat-statuses-data.store';
import { useMessagesStore } from './messages.store';
import { useUsersStore } from './users.store';

export interface IChatMetadata {
  writingUser: string | null;
  writingUserId: IUserData | null;
}

export interface IChatDataWithMetadata extends IChatData {
  metadata: Partial<IChatMetadata>;
}

/**
 * Chats activos del usuario autenticado.
 */
export const useChatsStore = defineStore('chats', {
  state: () => ({
    initializing: false,

    loadingMoreChats: false,

    /**
     * Current authenticated user.
     */
    userId: null as string | null,

    /**
     * All loaded chats.
     */
    items: [] as IChatData[],

    /**
     * Timestamps con la última comprobación de chats nuevos.
     *
     * Estado temporal para usar como workaround para la des-conexión aleatoria de Realm en que deja de leer los nuevos
     * cambios: https://github.com/realm/realm-js/issues/6481
     */
    lastCheckForNewChats: null as DateTime | null,

    /**
     * Stream con los cambios de los chats.
     */
    itemsChanges$: null as Observable<DocumentChangeEvent<ChatSchema>> | null,

    itemsChangesSubscription: null as Subscription | null,

    // /**
    //  * Indica los chats que no han sido leídos.
    //  *
    //  * TODO: Implementar.
    //  */
    // unreadItems: 0,
  }),

  getters: {
    /**
     * IDs of all loaded chats.
     */
    ids(): string[] {
      return this.items.map((item) => item.id);
    },

    // /**
    //  * Obtiene IDS de usuarios que están escribiendo en cada chat, mapeados por ID del chat.
    //  */
    // writingUsers(): Record<string, string[] | undefined> {
    //   // Nota: Undefined, porque podrían consultar un chat que no existe.

    //   const chats: Record<string, string[]> = {};

    //    const list = this.items.filter((item: IChatData) => item.participants.some((p: IParticipantData) => p.writing));

    //   // .map((item: IChatData) => {
    //   //   return item.participants.filter((p: IParticipantData) => p.writing).map((p: IParticipantData) => {
    //   //     return p.userId
    //   //   })
    //   // });

    //   list.forEach((item: IChatData) => {
    //     chats[item.id] = item.participants
    //       .filter((p: IParticipantData) => p.writing)
    //       .map((p: IParticipantData) => {
    //         return p.userId;
    //       });
    //   });

    //   return chats;
    // },

    /**
     * Obtiene al primer usuario que está escribiendo en cada chat, mapeados por ID del chat.
     */
    writingUser(): Record<string, IDataWithId<IUserData> | undefined> {
      // Nota: Undefined, porque podrían consultar un chat que no existe.
      // TODO: Mover a un state e ir detectándolo en los eventos de realm

      const chats: Record<string, IDataWithId<IUserData>> = {};

      const list = this.items.filter((item: IChatData) => item.participants.some((p: IParticipantData) => p.writing));

      // console.log('🅾️ writingUser', list);

      const usersStore = useUsersStore();

      list.forEach((item: IChatData) => {
        const userId = item.participants.find((p: IParticipantData) => p.writing)?.userId;
        chats[item.id] = {
          id: userId ?? null,
          data: usersStore.itemsMap[userId ?? ''] ?? null,
        };
      });

      return chats;
    },

    /**
     * Chat list ids are muted
     */
    mutedChatIds(): string[] {
      return this.items
        .filter(
          (item: IChatData) =>
            item.participants.find((p: IParticipantData) => p.userId === this.userId)?.subscribed === false
        )
        .map((chat: IChatData) => chat.id);
    },

    currentUserFavoriteChatIds(): string[] {
      return this.items
        .filter(
          (item: IChatData) =>
            item.participants.find((p: IParticipantData) => p.userId === this.userId)?.favorite === true
        )
        .map((chat: IChatData) => chat.id);
    },

    filterChatsWithMentions(): string[] {
      const userId = this.userId;
      if (!userId) return [];

      const messagesStore = useMessagesStore();

      const chatIdsWithMentions = this.items
        .filter(
          (chat) =>
            chat.participants.some((participant: IParticipantData) => participant.userId === userId) &&
            chat.type === 'group'
        )
        .filter((chat) =>
          Object.values(messagesStore.itemsMap[chat.id] || {}).some(
            (message) => message.mentions && message.mentions.includes(userId)
          )
        )
        .map((chat) => chat.id);

      console.log('🅾️ filterChatsWithMentions', chatIdsWithMentions); // DEBUG

      return chatIdsWithMentions;
    },

    chatsWithUnreadMentions(): string[] {
      const userId = this.userId;
      if (!userId) return [];

      const messagesStore = useMessagesStore();

      const chatIdsWithMentions = this.items
        .filter((chat) => chat.participants.some((participant) => participant.userId === userId))
        .filter((chat) =>
          Object.values(messagesStore.itemsMap[chat.id] || {}).some(
            (message) => message.mentions && message.mentions.includes(userId) && !message.seenBy[userId]
          )
        )
        .map((chat) => chat.id);

      return chatIdsWithMentions;
    },
  },

  actions: {
    /**
     * Initializes the user's chats.
     *
     * This should be run after login, when authenticated user is loaded.
     */
    async init(
      userId: string | BSON.ObjectID | null,
      initialItems?: Partial<IChatData>[] | Partial<ChatSchema>[]
    ): Promise<void> {
      const messagesStore = useMessagesStore();
      const chatStatusesDataStore = useChatStatusesDataStore();

      if (!userId) {
        this.clear();
        messagesStore.clear();
        chatStatusesDataStore.clear();

        return;
      }

      if (userId.toString() === this.userId) {
        if (this.itemsChanges$ && !!this.itemsChangesSubscription && !this.itemsChangesSubscription.closed) {
          // TODO: Add an option to force to reset the subscription
          return;
        }
      }

      messagesStore.init(userId);
      chatStatusesDataStore.init(userId);

      // TODO: Check if already initializing

      this.initializing = true;

      // this.unreadItems = 0;

      try {
        this.clear();

        this.userId = userId.toString();

        if (initialItems === undefined) {
          await this.loadMoreChats({
            limit: 15,
          });
        } else {
          this.addItem(...initialItems.map(mapChatSchemaToData));
        }

        const usersStore = useUsersStore();

        usersStore.addItemById(this.userId);

        const realm = realmService.getInstance();

        const db = await realm.connect();

        const collection = db.collection<ChatSchema>('chats');

        const itemsChanges = collection.watch({
          filter: {
            // Filtrar por el usuario siendo participante
            'fullDocument.participants.user': new BSON.ObjectID(userId),
          },
        }) as AsyncGenerator<DocumentChangeEvent<ChatSchema>, any, unknown>;
        // FIXME: Controlar eventos sin documentos (DropEvent, RenameEvent, etc)
        // TODO: Controlar cuando a un usuario lo sacan del chat

        this.itemsChanges$ = from(itemsChanges).pipe(shareReplay(1));

        this.itemsChangesSubscription = this.itemsChanges$
          .pipe(
            retry({
              count: 5,
              delay: () => {
                return defer(() =>
                  realm.currentUser?.isLoggedIn ? realm.currentUser.refreshAccessToken() : realm.connect()
                );
              },
            })
          )
          .subscribe({
            next: (change) => {
              // console.log('Change in subscription', change);

              // console.log('Chat ', change.operationType, change.documentKey._id.toString()); // DEBUG

              switch (change.operationType) {
                case 'insert':
                  this.addItem(mapChatSchemaToData(change.fullDocument));

                  break;

                case 'update':
                  if (change.fullDocument) {
                    // console.log('UpdatedFiles 😁❤️', Object.keys(change.updateDescription.updatedFields).some((key) =>/participants\..+\.favorite/.test(key)))
                    if (
                      this.updateItem(mapChatSchemaToData(change.fullDocument)) &&
                      // Sólo reordenar si ha cambiado el lastMessageAt
                      (change.updateDescription.updatedFields.last_message_at ||
                        Object.keys(change.updateDescription.updatedFields).some((key) =>
                          /participants\..+\.favorite/.test(key)
                        ))
                    ) {
                      this.sort();
                    }
                  }

                  break;

                case 'replace':
                  if (this.updateItem(mapChatSchemaToData(change.fullDocument))) {
                    this.sort();
                  }

                  break;

                case 'delete':
                  this.removeItem(change.documentKey._id.toString());

                  break;
              }
            },
            error: (err) => {
              console.error('Error in chats$ subscription', err);
            },
            complete: () => {
              console.log('chats$ subscription completed');
            },
          });
      } catch (err) {
        console.error('Error initializing the Chats store', err);
      } finally {
        this.initializing = false;
      }
    },

    async loadChatsSince(chatId: string): Promise<void> {
      if (!this.userId) {
        return;
      }

      this.loadingMoreChats = true;

      try {
        const items = await realmService.getInstance().getUserChats(this.userId, { skip: 0, limit: 30, since: chatId });

        this.addItem(...items.map(mapChatSchemaToData));

        this.lastCheckForNewChats = DateTime.now();
      } catch (err) {
        // TODO: Detectar si es de autenticación
        console.error('loadMoreChats', err);

        throw err;
      } finally {
        this.loadingMoreChats = false;
      }
    },

    /**
     * Carga los chats nuevos, desde el último ID.
     */
    async loadLastChats(): Promise<void> {
      if (this.items.length === 0) {
        await this.loadMoreChats();
      }

      await this.loadChatsSince(this.items[this.items.length - 1].id);
    },

    async refreshChats(): Promise<void> {
      if (!this.userId) {
        return;
      }

      this.loadingMoreChats = true;

      const limit = this.items.length + 5;

      try {
        const items = await realmService.getInstance().getUserChats(this.userId, { skip: 0, limit });

        this.addItem(...items.map(mapChatSchemaToData));

        this.lastCheckForNewChats = DateTime.now();
      } catch (err) {
        // TODO: Detectar si es de autenticación
        console.error('loadMoreChats', err);

        throw err;
      } finally {
        this.loadingMoreChats = false;
      }
    },

    /**
     * Carga chats más viejos del usuario que aún no se hayan cargado.
     *
     * Útil para hacer scrolls con carga parcial.
     */
    async loadMoreChats({ limit = 15 }: { limit?: number } = {}): Promise<void> {
      if (!this.userId) {
        return;
      }

      this.loadingMoreChats = true;

      const skip = this.items.length;

      try {
        const items = await realmService.getInstance().getUserChats(this.userId, { skip, limit });

        await this.addItem(...items.map(mapChatSchemaToData));

        this.lastCheckForNewChats = DateTime.now();
      } catch (err) {
        // TODO: Detectar si es de autenticación
        console.error('loadMoreChats', err);

        throw err;
      } finally {
        this.loadingMoreChats = false;
      }
    },

    async addItem(...items: IChatData[]): Promise<void> {
      const itemsToUpdate: IChatData[] = [];
      const itemsToAdd: IChatData[] = [];

      items.forEach((newItem) => {
        if (this.ids.includes(newItem.id)) {
          itemsToUpdate.push(newItem);
        } else {
          itemsToAdd.push(newItem);
        }
      });

      let someItemUpdated = false;

      if (itemsToUpdate.length > 0) {
        // TODO: Comprobar ciclo infinito

        itemsToUpdate.forEach((item) => {
          someItemUpdated ||= this.updateItem(item);
        });
      }

      if (someItemUpdated) {
        this.sort();
      }

      if (itemsToAdd.length === 0) {
        return;
      }

      this.items.push(...itemsToAdd);

      const messagesStore = useMessagesStore();

      this.pauseOrResumeMessagesDependingOfParticipant(...items);

      await messagesStore.addChat(...this.ids);

      const participantUserIdsToTrack = items
        .filter((item) => item.type === 'individual')
        .map((item) => item.participants)
        .flatMap((participant) => [...participant])
        .map((p) => p.userId);

      if (participantUserIdsToTrack.length > 0) {
        const usersStore = useUsersStore();

        usersStore.addItemById(...participantUserIdsToTrack);
      }

      this.sort();
    },

    /**
     * Valida que el usuario autenticado pertenezca a los participantes.
     *
     * En caso de no pertenecer, se pausan los mensajes para el chat.
     */
    pauseOrResumeMessagesDependingOfParticipant(...chats: IChatData[]): void {
      const messagesStore = useMessagesStore();

      const chatsToPause: string[] = [];

      const chatsToResume: string[] = [];

      chats.forEach((chat) => {
        // Comparar si el usuario actual ya no está en la lista de participants
        if (!chat.participants.find((participant) => participant.userId === this.userId)) {
          // Se puede comparar con el valor anterior y tal

          console.warn(`Chat ${chat.id} no tiene el usuario actual (${this.userId}) como participante. Pausando.`); // DEBUG
          chatsToPause.push(chat.id);
        } else {
          chatsToResume.push(chat.id);
        }
      });

      if (chatsToPause.length > 0) {
        messagesStore.pauseMessagesFrom(...chatsToPause);
      }

      if (chatsToResume.length > 0) {
        messagesStore.resumeMessagesFrom(...chatsToResume);
      }
    },

    /**
     * Agrega un nuevo elemento.
     *
     * @returns Indica si el elemento sí existía. Retorna `false` cuando el elemento fue agregado al final.
     */
    updateItem(item: IChatData): boolean {
      const itemIndex = this.items.findIndex((i) => i.id === item.id);
      if (itemIndex === -1) {
        this.addItem(item);
      } else {
        // const currentItem: IChatData = this.items[itemIndex];
        // TODO: Comprobar el updatedAt para detectar cuál es el último

        this.items.splice(itemIndex, 1, item);

        // // Corrección para que se detecte apropiadamente el cambio de participantes
        // console.log(
        //   '🅰️ old participants',
        //   currentItem.participants.map((participant) => participant.userId)
        // ); // DEBUG
        // console.log(
        //   '🅱️ new participants',
        //   item.participants.map((participant) => participant.userId)
        // ); // DEBUG

        // if (item.participants.length > 0) {
        //   item.participants = [...item.participants];
        // }

        if (item.type === 'individual') {
          // Para el chat grupal se debe inicializar al entrar a ese chat

          const participantUserIdsToTrack = item.participants.map((participant) => participant.userId);

          if (participantUserIdsToTrack.length > 0) {
            const usersStore = useUsersStore();

            usersStore.addItemById(...participantUserIdsToTrack);
          }
        }

        this.pauseOrResumeMessagesDependingOfParticipant(item);
      }

      return itemIndex !== -1;
    },

    /**
     * Elimina el elemento, si existe.
     */
    removeItem(item: string | IChatData): boolean {
      const itemId = typeof item === 'string' ? item : item.id;

      const itemIndex = this.items.findIndex((i) => i.id === itemId);

      if (itemIndex !== -1) {
        this.items.splice(itemIndex, 1);
      }

      return itemIndex !== -1;
    },

    /**
     * Ordena inversamente por el último mensaje recibido.
     */
    // sort() {
    //   this.items.sort((a, b) => {
    //     if (this.currentUserFavoriteChatIds.includes(a.id) && !this.currentUserFavoriteChatIds.includes(b.id)) {
    //       return -1;
    //     } else if (this.currentUserFavoriteChatIds.includes(b.id) && !this.currentUserFavoriteChatIds.includes(a.id)) {
    //       return 1;
    //     } else if (this.currentUserFavoriteChatIds.includes(a.id) && this.currentUserFavoriteChatIds.includes(b.id)) {
    //       return 0;
    //     }
    //     // TODO: Verificar el ordenamiento cuando lastMessageAt es null
    //     return ((b.lastMessageAt ?? b.createdAt)?.valueOf() ?? 0) - ((a.lastMessageAt ?? a.createdAt)?.valueOf() ?? 0);
    //   });
    // },

    /**
     * Ordenamiento de los chats pineados como de los no pineados.
     */

    sort() {
      this.items.sort((a, b) => {
        const aFavorite = this.currentUserFavoriteChatIds.includes(a.id);
        const bFavorite = this.currentUserFavoriteChatIds.includes(b.id);

        if (aFavorite && !bFavorite) {
          return -1;
        } else if (!aFavorite && bFavorite) {
          return 1;
        }

        const aLastInteraction = a.lastMessageAt ?? a.createdAt;
        const bLastInteraction = b.lastMessageAt ?? b.createdAt;

        return (bLastInteraction?.valueOf() ?? 0) - (aLastInteraction?.valueOf() ?? 0);
      });
    },

    /**
     * Reinicia el estado del store.
     */

    clear(): void {
      this.userId = null;
      this.items = [];

      if (this.itemsChangesSubscription) {
        this.itemsChangesSubscription.unsubscribe();

        this.itemsChanges$ = null;
      }
    },

    /**
     * Check if a chat is muted
     */
    isMuted(chatId: string): boolean {
      return this.mutedChatIds.includes(chatId);
    },

    isFavourite(chatId: string): boolean {
      return this.currentUserFavoriteChatIds.includes(chatId);
    },

    hasMentions(chatId: string): boolean {
      return this.chatsWithUnreadMentions.includes(chatId);
    },

    /**
     * Find individual chat by userId
     */
    findByIndividualParticipant(userId: string): IChatData | undefined {
      return this.items.find(
        (item: IChatData) =>
          item.type === 'individual' &&
          item.participants.find((participant: IParticipantData) => participant.userId === userId)
      );
    },
  },
});
