import { isNil, isPlainObject, omitBy } from 'lodash-es';
import { BSON } from 'realm-web';
import { defaultedData } from '../../utils/helpers';
import { IParticipantData, Participant, mapParticipantSchemaToData } from './participant-schema';
import { SchemaBase } from './schema-base';

export interface ChatSchema extends SchemaBase {
  client: BSON.ObjectID;
  company: BSON.ObjectID;
  participants: Participant[];
  type?: 'individual' | 'group';
  subject: string | null;
  image: string | null;
  last_message_at?: Date;
}

export type ChatType = 'individual' | 'group';

/**
 * Datos saneados desde ChatSchema para ser usados.
 */
export interface IChatData {
  id: string;
  companyId: string | null;
  subject: string;
  image: string;
  type: ChatType;
  participants: IParticipantData[];

  /**
   * Último momento en el que se ha enviado un mensaje a este chat.
   */
  lastMessageAt: Date | null;

  createdAt: Date | null;
  updatedAt: Date | null;
}

/**
 * Convierte un objeto ChatSchema parcial a IChatData normalizado.
 *
 * @param partialData
 * @returns
 */
export const mapChatSchemaToData = (partialData: Partial<ChatSchema> | Partial<IChatData>): IChatData => {
  // TODO: Mover al namespace, como con IMessageData
  const defaultValue: IChatData = {
    id: '',
    subject: '',
    image: '',
    companyId: null,
    type: 'individual',
    participants: [],
    createdAt: null,
    updatedAt: null,
    lastMessageAt: null,
  };

  // Auxiliar para poder operar como si fuera cualquiera de los 2 tipos
  const mergedPartialData = partialData as Partial<ChatSchema> & Partial<IChatData>;

  const dataFromSchema: Partial<IChatData> = {
    id: mergedPartialData._id?.toString() ?? mergedPartialData.id,
    companyId: mergedPartialData.company?.toString() ?? mergedPartialData.companyId,
    subject: mergedPartialData.subject ?? undefined,
    image: mergedPartialData.image ?? undefined,
    type: mergedPartialData.type,
    lastMessageAt: mergedPartialData.last_message_at ?? mergedPartialData.lastMessageAt,
    createdAt: mergedPartialData.created_at ?? mergedPartialData.createdAt,
    updatedAt: mergedPartialData.updated_at ?? mergedPartialData.updatedAt,
    participants: mergedPartialData.participants?.map((participant) => {
      return mapParticipantSchemaToData(participant);
    }),
  };

  const data = defaultedData(omitBy(dataFromSchema, isNil), defaultValue);

  return data;
};

export namespace IChatData {
  /**
   * Detecta en tiempo de ejecución si un objeto cumple con la interfaz IChatData.
   */
  export const is = (data: unknown): data is IChatData => {
    if (!data || !isPlainObject(data)) {
      return false;
    }

    const defaultValue: IChatData = {
      id: '',
      subject: '',
      image: '',
      companyId: null,
      type: 'individual',
      participants: [],
      createdAt: null,
      updatedAt: null,
      lastMessageAt: null,
    };

    const someKeysAreMissing = Object.keys(defaultValue).some((key) => !Object.keys(data).includes(key));

    if (someKeysAreMissing) {
      return false;
    }

    // TODO: Mejorar la comprobación de tipo.

    return true;
  };
}
