import { isNil, omitBy } from 'lodash-es';
import { defaultedData } from '../../utils/helpers';
import { SchemaBase } from '../schema/schema-base';

/**
 * Interfaz base para los datos normalizados a partir de un Schema.
 */
export interface IBaseData {
  id: string;
  createdAt: Date | null;
  updatedAt: Date | null;
}

export namespace IBaseData {
  export const defaulted = (partialData: Partial<IBaseData> = {}): IBaseData => {
    const defaultData: IBaseData = {
      id: '',
      createdAt: null,
      updatedAt: null,
    };

    return defaultedData(partialData, defaultData);
  };

  export const normalizedFrom = (partialData: Partial<SchemaBase> | Partial<IBaseData> = {}): IBaseData => {
    // Auxiliar para poder operar como si fuera cualquiera de los 2 tipos
    const mergedPartialData = partialData as Partial<SchemaBase>;

    const dataFromSchema: Partial<IBaseData> = {
      id: mergedPartialData._id?.toString(),
      createdAt: mergedPartialData.created_at,
      updatedAt: mergedPartialData.updated_at,
    };

    return defaultedData(omitBy(dataFromSchema, isNil), defaulted(partialData as Partial<IBaseData>));
  };
}

export interface IDataWithId<TData = IBaseData> {
  id: string | null;
  data: TData | null;
}
