import { IErrorResponse, ISuccessResponse, tryRequest } from '@/core/axios';
import { realmService } from '@/services/realm.service';
import { useAuthenticationStore } from '@/stores/authentication.store';
import { ServiceProvider } from '@/support/service-provider';
import { AxiosInstance } from 'axios';

export interface IPasswordCredentials {
  email: string;
  password: string;
}

export interface ICodeCredentials {
  // email: string;
  code: string;
}

export interface ITokenCredentials {
  token: string;
}
export interface IRefreshTokenCredentials {
  refreshToken: string;
}

export interface ICheckTokenData {
  id: string;
  name: string;
}

export interface IOauth2TokenData {
  access_token: string;
  /**
   * Expiration time, in seconds.
   */
  expires_in: number;
  refresh_token: string;
  scope: null;
  token_type: 'bearer';
}

// FIXME: Unificar parse de respuesta

/**
 * Punto de entrada para el flujo de autenticación.
 */
export class AuthenticationServiceProvider extends ServiceProvider {
  constructor(protected readonly api: AxiosInstance) {
    super();
  }

  /**
   * Login con contraseña.
   *
   * Si no está autorizado aún, genera el código de verificación para las credenciales especificadas.
   */
  public async signInUsingPassword(
    credentials: IPasswordCredentials
  ): Promise<ISuccessResponse<IOauth2TokenData> | IErrorResponse> {
    // TODO: Exponer tipo
    const oauth2Credentials = {
      client_id: import.meta.env.VITE_API_CLIENT_ID,
      client_secret: import.meta.env.VITE_API_CLIENT_SECRET,
      grant_type: 'password',
      username: credentials.email,
      password: credentials.password,
    };

    const [
      error,
      result,
    ] = await tryRequest(this.api.post<IOauth2TokenData>(`/v2/auth/login`, oauth2Credentials));

    if (error) {
      return error;
    }

    const authenticationStore = useAuthenticationStore();

    await authenticationStore.init({ tokenData: result.data.body });

    try {
      await realmService.getInstance().connect({ force: true });
    } catch (error) {
      console.warn('Current user token is not able to connect to Realm service. Verification ir needed.', error);
    }

    return result;
  }

  /**
   * Valida el código de verificación cuando es necesario.
   */
  public async signInUsingCode(credentials: ICodeCredentials): Promise<ISuccessResponse<null> | IErrorResponse> {
    // TODO: Exponer tipo
    const codeCredentials = {
      ...credentials,
      client_id: import.meta.env.VITE_API_CLIENT_ID,
      client_secret: import.meta.env.VITE_API_CLIENT_SECRET,
      grant_type: 'code',
    };

    const [
      error,
      result,
    ] = await tryRequest(this.api.post<null>(`/v2/auth/validate-code`, codeCredentials));

    if (error) {
      return error;
    }

    const authenticationStore = useAuthenticationStore();

    await authenticationStore.syncTokenUser();

    try {
      await realmService.getInstance().connect({ force: true });
    } catch (error) {
      console.warn('Current user token is not able to connect to Realm service. Verification ir needed.', error);
    }

    return result;
  }

  /**
   * Almacena el token de acceso para poder autenticar las peticiones.
   */
  public async signInUsingToken(credentials: ITokenCredentials) {
    // console.log(credentials);

    // FIXME: Implementar

    throw new Error('Not implemented yet!');
  }

  /**
   * Hace un refresh de un token ya existente y lo guarda en el store correspondiente.
   */
  public async signInUsingRefreshToken(credentials: IRefreshTokenCredentials) {
    // TODO: Exponer tipo
    const oauth2Credentials = {
      client_id: import.meta.env.VITE_API_CLIENT_ID,
      client_secret: import.meta.env.VITE_API_CLIENT_SECRET,
      grant_type: 'refresh_token',
      refresh_token: credentials.refreshToken,
    };

    const [
      error,
      result,
    ] = await tryRequest(this.api.post<IOauth2TokenData>(`/v2/auth/token`, oauth2Credentials));

    if (error) {
      return error;
    }

    const authenticationStore = useAuthenticationStore();

    await authenticationStore.init({ tokenData: result.data.body });

    try {
      await realmService.getInstance().connect({ force: true });
    } catch (error) {
      console.warn('Current user token is not able to connect to Realm service. Verification ir needed.', error);
    }

    return result;
  }

  /**
   * Resets the auth state and cleans the local data.
   */
  public async signOut() {
    const authenticationStore = useAuthenticationStore();

    authenticationStore.clear();

    await realmService.getInstance().currentUser?.logOut();
  }

  public async getAuthenticatedUser() {
    return await tryRequest(
      this.api.get<{ email: string; id: string; name: string; last_name: string }>(`/v2/auth/profile`)
    );
  }

  /**
   * Establece una nueva contraseña para el usuario especificado para recuperar acceso a su cuenta.
   */
  public async resetPassword(resetToken: string, data: { email: string; password: string; password2: string }) {
    return await tryRequest(this.api.post<null>(`/v1/reset-password`, { ...data, token: resetToken }));
  }

  /**
   * Establece la contraseña inicial cuando se crea una cuenta o se invalida un token.
   */
  public async validateAccount(
    temporalAccessToken: string,
    data: {
      password: string;
      password2: string;
    }
  ) {
    return await tryRequest(
      this.api.post<null>(`/v1/auth/validate-account`, data, {
        headers: {
          Authorization: `Bearer ${temporalAccessToken}`,
        },
      })
    );
  }

  public async validateResetPasswordToken(validationToken: string) {
    // TODO: Actualizar a v2
    return await tryRequest(this.api.get<{ email: string }>(`/v1/validate-token/${validationToken}`));
  }
}
