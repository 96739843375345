import { isEnabled } from '@/utils/feature-flags';
import { RouteRecordRaw } from 'vue-router';

/**
 * Routes under AuthLayout.
 */
const authRoutes: RouteRecordRaw = {
  path: '/auth',
  component: () => import('@/layouts/AuthLayout.vue'),
  children: [
    {
      path: '',
      redirect: { name: 'auth.login' },
    },
    {
      path: 'login',
      name: 'auth.login',
      component: () => import('@/pages/auth/LoginPage.vue'),
      meta: {
        auth: {
          requireGuest: true,
          redirectPathIfUnauthorized: { name: 'chats.index' },
        },
      },
    },
    {
      path: 'forget-password',
      name: 'auth.forget-password',
      component: () => import('@/pages/auth/ForgetPasswordPage.vue'),
      meta: {
        auth: {
          requireGuest: true,
          redirectPathIfUnauthorized: { name: 'chats.index' },
        },
      },
    },
    {
      path: 'new-password',
      name: 'auth.new-password',
      component: () => import('@/pages/auth/ResetPasswordPage.vue'),
      meta: {
        auth: {
          requireGuest: true,
          redirectPathIfUnauthorized: { name: 'chats.index' },
        },
      },
    },
    {
      path: 'reset-password/:token',
      name: 'auth.reset-password',
      component: () => import('@/pages/auth/ResetPasswordPage.vue'),
      meta: {
        auth: {
          requireGuest: true,
          redirectPathIfUnauthorized: { name: 'chats.index' },
        },
      },
    },
  ],
};

/**
 * Routes under  MainLayout
 */
const mainRoutes: RouteRecordRaw = {
  path: '/',
  component: () => import('@/layouts/MainLayout.vue'),

  children: [
    {
      path: '/chats',
      component: () => import('@/pages/chats/Layout.vue'),
      meta: {
        auth: {
          requireAuthenticated: true,
          redirectPathIfUnauthorized: { name: 'auth.login' },
        },
      },
      children: [
        {
          path: '',
          name: 'chats.index',
          component: () => import('@/pages/chats/IndexPage.vue'),
          meta: {
            auth: {
              requireAuthenticated: true,
              redirectPathIfUnauthorized: { name: 'auth.login' },
            },
          },
        },
        {
          path: ':chatId',
          name: 'chats.show',
          component: () => import('@/pages/chats/ShowPage.vue'),
          meta: {
            auth: {
              requireAuthenticated: true,
              redirectPathIfUnauthorized: { name: 'auth.login' },
            },
          },
        },
        {
          path: ':chatId/tickets',
          name: 'chats.tickets',
          component: () => import('@/pages/chats/TicketsPage.vue'),
          meta: {
            auth: {
              requireAuthenticated: true,
              redirectPathIfUnauthorized: { name: 'auth.login' },
            },
          },
        },
        {
          path: ':chatId/activities',
          name: 'chats.activities',
          component: () => import('@/pages/chats/ActivitiesPage.vue'),
          meta: {
            auth: {
              requireAuthenticated: true,
              redirectPathIfUnauthorized: { name: 'auth.login' },
            },
          },
        },
      ],
    },

    {
      path: '/new-home',
      name: 'new-home',
      component: () => import('../pages/NewHomePage.vue'),
      meta: {
        auth: {
          requireAuthenticated: true,
          redirectPathIfUnauthorized: { name: 'auth.login' },
        },
      },
    },
    {
      path: '/users',
      name: 'users.index',
      component: () => import('../pages/UsersPage.vue'),
      meta: {
        auth: {
          requireAuthenticated: true,
          redirectPathIfUnauthorized: { name: 'auth.login' },
        },
      },
      children: [
        {
          path: '',
          name: 'users.empty',
          component: () => import('../pages/users/IndexPage.vue'),
          meta: {
            auth: {
              requireAuthenticated: true,
              redirectPathIfUnauthorized: { name: 'auth.login' },
            },
          },
        },
        {
          path: ':userId',
          name: 'users.show',
          component: () => import('../pages/users/ShowPage.vue'),
          meta: {
            auth: {
              requireAuthenticated: true,
              redirectPathIfUnauthorized: { name: 'auth.login' },
            },
          },
        },
        {
          path: 'contacts/:userId',
          name: 'users.contacts',
          component: () => import('../pages/users/ContactsPage.vue'),
          meta: {
            auth: {
              requireAuthenticated: true,
              redirectPathIfUnauthorized: { name: 'auth.login' },
            },
          },
        },
        {
          path: 'mycontacts',
          name: 'users.agentContacts',
          component: () => import('../pages/users/OperatorPage.vue'),
          meta: {
            auth: {
              requireAuthenticated: true,
              redirectPathIfUnauthorized: { name: 'auth.login' },
            },
          },
        },
      ],
    },
    {
      path: '/profile',
      name: 'new-profile',
      component: () => import('@/pages/User/NewProfile.vue'),
      meta: {
        auth: {
          requireAuthenticated: true,
          redirectPathIfUnauthorized: { name: 'auth.login' },
        },
      },
    },
  ],
};

const settingRoutes: RouteRecordRaw = {
  path: '/settings',
  component: () => import('@/layouts/V1MainLayout.vue'),
  children: [
    {
      path: '',
      component: () => import('@/pages/Settings/Layout.vue'),
      children: [
        {
          path: '',
          name: 'settings.index',
          component: import('@/pages/Settings/IndexPage.vue'),
          meta: {
            auth: {
              requireAuthenticated: true,
              redirectPathIfUnauthorized: { name: 'auth.login' },
            },
          },
        },
      ],
    },
  ],
};

// Adding digital signature module routes conditionally
if (isEnabled('DIGITAL_SIGNATURE')) {
  mainRoutes.children.push({
    path: '/signature',
    component: () => import('@/pages/signature/Layout.vue'),
    children: [
      {
        path: '',
        name: 'signature.index',
        component: () => import('@/pages/signature/IndexPage.vue'),
        meta: {
          auth: {
            requireAuthenticated: true,
            redirectPathIfUnauthorized: { name: 'auth.login' },
          },
        },
      },
      {
        path: 'editor',
        name: 'signature.editor',
        component: () => import('@/pages/signature/EditorPage.vue'),
        meta: {
          auth: {
            requireAuthenticated: true,
            redirectPathIfUnauthorized: { name: 'auth.login' },
          },
        },
      },
    ],
  });
}

const routes: RouteRecordRaw[] = [
  {
    path: '/home',
    children: [
      {
        path: '',
        name: 'home',
        redirect: { name: 'chats.index' },
      },
      {
        path: 'users',
        name: 'users',
        redirect: { name: 'users.index' },
      },
      {
        path: 'clients',
        name: 'clients',
        redirect: { name: 'users.index' },
      },
      {
        path: 'profile',
        name: 'profile',
        redirect: { name: 'chats.index' },
      },
      {
        path: 'chats',
        name: 'chats',
        redirect: { name: 'chats.index' },
      },
      {
        path: 'files',
        name: 'files',
        redirect: { name: 'chats.index' },
      },
    ],
  },

  authRoutes,

  // Se mantienen los mismos nombres de ruta para retro-compatibilidad.
  {
    path: '/',
    // TODO: Eliminar ruta / para manejar contenido en vez de login
    strict: true,
    name: 'login',
    redirect(to) {
      return { params: to.params, query: to.query, name: 'auth.login' };
    },
  },
  {
    path: '/forgetPassword',
    name: 'ForgetPassword',
    redirect: { name: 'auth.forget-password' },
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    redirect(to) {
      return { params: to.params, query: to.query, name: 'auth.reset-password' };
    },
  },
  {
    path: '/new-password',
    name: 'NewPassword',
    redirect: { name: 'auth.new-password' },
  },

  // Nuevas rutas
  mainRoutes,

  /**
   * - this route is the one where user will interact with document (contract).
   * - based on the design, it seems to be outside our main layout, however, it shares a similar ui to editor page.
   */
  {
    path: '/document',
    name: 'document.index',
    component: () => import('@/pages/signature/DocumentPage.vue'),
  },

  // Settings Routes
  settingRoutes,

  // FIXME: Reemplazar con un 404
  { path: '/:pathMatch(.*)*', redirect: '/' },
];

export default routes;
