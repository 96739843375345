<template>
  <div id="c-action-picker" :class="buttonClass">
    <BaseButton
      v-if="!hideSecondaryActionButton"
      :class="vertical ? 'mb-2' : 'mr-2'"
      :color="cancelButtonColor"
      :block="cancelButtonBlock"
      :disabled="cancelButtonDisable"
      :variant="cancelButtonVariant"
      data-type="secondary"
      @click="cancel"
    >
      <p :class="cancelButtonTextClass">
        {{ cancelText }}
      </p>
    </BaseButton>
    <BaseButton
      v-if="!hideMainActionButton"
      :color="confirmButtonColor"
      :block="confirmButtonBlock"
      :disabled="confirmButtonDisable"
      :variant="confirmButtonVariant"
      @click="confirm"
    >
      <p :class="confirmButtonTextClass">
        {{ confirmText }}
      </p>
    </BaseButton>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  hideMainActionButton: { type: Boolean, default: false },
  hideSecondaryActionButton: { type: Boolean, default: false },
  confirmText: { type: String, default: 'Confirm' },
  cancelText: { type: String, default: 'Cancel' },
  centered: { type: Boolean, default: false },
  leftAligned: { type: Boolean, default: false },
  rightAligned: { type: Boolean, default: false },
  spaced: { type: Boolean, default: false },
  cancelButtonColor: { type: String, default: '' },
  confirmButtonColor: { type: String, default: 'primary' },
  cancelButtonTextClass: { type: String, default: '' },
  confirmButtonTextClass: { type: String, default: '' },
  cancelButtonBlock: { type: Boolean, default: false },
  confirmButtonBlock: { type: Boolean, default: false },
  cancelButtonDisable: { type: Boolean, default: false },
  confirmButtonDisable: { type: Boolean, default: false },
  cancelButtonVariant: { type: String, default: 'elevated' },
  confirmButtonVariant: { type: String, default: 'elevated' },
  vertical: { type: Boolean, default: false },
});

const emit = defineEmits<{
  confirm: [];
  cancel: [];
}>();

const buttonClass = computed(() => {
  let customClass = 'w-100';

  if (!props.cancelButtonBlock && !props.confirmButtonBlock) {
    customClass += ' d-flex align-center';
  }

  if (props.vertical) customClass += ' flex-column';
  if (props.centered) customClass += ' justify-center';
  if (props.leftAligned) customClass += ' justify-start';
  if (props.rightAligned) customClass += ' justify-end';
  if (props.spaced) customClass += ' justify-space-between';

  return customClass;
});

function confirm() {
  emit('confirm');
}
function cancel() {
  emit('cancel');
}
</script>
