import { defineStore } from 'pinia';
import LocalStorageService from '../services/localStore.service';
import { LoginRequest } from './../models/services/auth/login-req';
import { UserModel } from './../models/services/auth/user';
import { useAuthenticationStore } from './authentication.store';
import { usePermissionsStore } from './permission-store';

/**
 * @deprecated
 * @see useAuthenticationStore
 */
export const useAuthStore = defineStore('auth', {
  state: () => ({
    // uid: '',
    user: {} as UserModel,
    // token: '',
    // role: '',
    email: '',
    avatar: '',
    date: Date.now(),
    fullName: '',
    login: {} as LoginRequest,
    remember: false,
    isFirstLogin: false,
    firstLoginEmail: '',
    // loggedIn: false,
    localStoreService: new LocalStorageService(),
  }),
  getters: {
    /**
     * @deprecated
     * @see useAuthenticationStore
     */
    loggedIn(): boolean {
      const authenticationStore = useAuthenticationStore();

      return authenticationStore.hasAuthorizedToken === true;
    },
    /**
     * Role name.
     *
     * @deprecated
     * @see useAuthenticationStore
     * @see useRolesStore
     * @see usePermissionsStore
     */
    role(): string | null {
      const permissionsStore = usePermissionsStore();

      return permissionsStore.role;
    },
    /**
     * @deprecated
     * @see useAuthenticationStore
     */
    token(): string | null {
      const authenticationStore = useAuthenticationStore();

      return authenticationStore.token;
    },
    /**
     * @deprecated
     * @see useAuthenticationStore
     */
    uid(): string {
      const authenticationStore = useAuthenticationStore();

      return authenticationStore.currentUserId ?? '';
    },
  },
  actions: {
    setUser(value: UserModel) {
      this.user = value;
      this.localStoreService.setUser(value);
    },
    // setToken(token: string) {
    //   this.token = token;
    //   this.localStoreService.setToken(token);
    // },
    // setRole(role: string) {
    //   this.role = role;
    //   this.localStoreService.setRole(role);
    // },
    setLogin(login: LoginRequest) {
      this.login = login;
      this.localStoreService.setLogin(login);
    },
    resetLogin() {
      this.localStoreService.resetLogin();
    },
    setRemember(remember: boolean) {
      this.remember = remember;
      this.localStoreService.setRemember(remember);
    },
    setFirstLogin(value: boolean) {
      this.isFirstLogin = value;
    },
    // setLoggedIn(value: boolean) {
    //   this.loggedIn = value;
    // },
    setFirstLoginEmail(value: string) {
      this.firstLoginEmail = value;
    },
    logout() {
      this.localStoreService.logout();

      this.clear();
    },
    async loadStore() {
      console.log('loadStore');
      this.user = this.localStoreService.getUser()!;
      // this.uid = this.localStoreService.getId()!;
      // this.token = this.localStoreService.getToken()!;
      // this.role = this.localStoreService.getRole()!;
      this.email = this.localStoreService.getEmail()!;
      this.avatar = this.localStoreService.getAvatar()!;
      this.fullName = this.localStoreService.getName()!;
      this.login = this.localStoreService.getLogin()!;
      this.remember = this.localStoreService.getRemember();

      // this.setLoggedIn(!!this.uid);

      console.log({ userId: this.uid, token: this.token });

      // TODO: Comprobar en dónde se inicializa realmente el usuario autenticado para poder cargar sus chats

      // const chats = useChatsStore();
      // chats.init(this.uid || null);
    },
    updateDate() {
      this.date = Date.now();
    },

    clear() {
      this.user = {} as UserModel;
      // this.token = '';
      // this.role = '';
      this.email = '';
      this.avatar = '';
      this.fullName = '';
      this.login = {} as LoginRequest;
      this.remember = false;
      this.isFirstLogin = false;
      this.firstLoginEmail = '';
      // this.loggedIn = false;
    },
  },
});
