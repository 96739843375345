import { isNil, omitBy } from 'lodash-es';
import { BSON } from 'realm-web';
import { defaultedData } from '../../utils/helpers';
import { SchemaBase } from './schema-base';

export interface Participant extends SchemaBase {
  enabled: boolean;
  level: Level;
  user: BSON.ObjectID;
  writing: boolean;
  subscribed: boolean;
  favorite?: boolean;
  unsubscribedUntil: Date;
}

export type Level = 'client' | 'creator' | 'invited';

export interface IParticipantData {
  id: string;
  createdAt: Date | null;
  updatedAt: Date | null;

  enabled: boolean;
  level: Level;
  userId: string;
  writing: boolean;
  subscribed: boolean;
  favorite: boolean;
  unsubscribedUntil: Date | null;
}

/**
 * Convierte un objeto Participant parcial a IParticipantData normalizado.
 *
 * @param partialData
 * @returns
 */
export const mapParticipantSchemaToData = (
  partialData: Partial<Participant> | Partial<IParticipantData>
): IParticipantData => {
  const defaultValue: IParticipantData = {
    id: '',
    createdAt: null,
    updatedAt: null,
    enabled: false,
    level: 'client',
    userId: '',
    writing: false,
    subscribed: true,
    favorite: false,
    unsubscribedUntil: null,
  };

  const mergedPartialData = partialData as Partial<Participant> & Partial<IParticipantData>;

  const dataFromSchema: Partial<IParticipantData> = {
    id: mergedPartialData._id?.toString() ?? mergedPartialData.id,
    createdAt: mergedPartialData.created_at ?? mergedPartialData.createdAt,
    updatedAt: mergedPartialData.updated_at ?? mergedPartialData.updatedAt,
    enabled: mergedPartialData.enabled === true,
    level: mergedPartialData.level,
    userId: mergedPartialData.user?.toString() ?? mergedPartialData.userId,
    writing: mergedPartialData.writing === true,
    subscribed: mergedPartialData.subscribed ?? true,
    favorite: mergedPartialData.favorite,
    unsubscribedUntil: mergedPartialData.unsubscribedUntil,
  };

  return defaultedData(omitBy(dataFromSchema, isNil), defaultValue);
};
