import { defineStore } from 'pinia';
import { useAuthenticationStore } from './authentication.store';
import { useRolesStore } from './roles.store';

export const usePermissionsStore = defineStore('permissions', {
  // FIXME: Mover a AuthenticationStore
  state: () => ({
    // canDeleteUserFromChat: false,
    // canManageUser: false,
    // canManageClient: false,
    // canEditProfile: false,
    // canAccessChatFiles: false,
    // canUploadChatFiles: false,
    // CanCreateMassMessage: false,
    // canCreateChat: false,
    // canCreateGroup: false,
    // canEditNameGroup: false,
    // canAddUserToChat: false,
    // canIntoHome: false,
    // canReviewChatsSettings: false,
    // canViewUsersOperator: false,
    // canViewUsersAdmin: false,
    // isClient: false,
    // isAdmin: false,
    // isSuperAdmin: false,
    // isOperator: false,
    // role: '',
  }),
  getters: {
    role(): string {
      const authStore = useAuthenticationStore();

      if (!authStore.currentUser?.roleId) {
        return '';
      }

      const rolesStore = useRolesStore();

      return rolesStore.itemsMap[authStore.currentUser.roleId]?.name ?? '';
    },
    isAdmin(): boolean {
      return this.role == 'admin';
    },
    isSuperAdmin(): boolean {
      return this.role == 'super-admin';
    },
    isOperator(): boolean {
      return this.role == 'operator';
    },
    isClient(): boolean {
      return this.role === 'client';
    },
    canDeleteUserFromChat(): boolean {
      return this.isAdmin || this.isSuperAdmin;
    },
    canManageUser(): boolean {
      return this.isAdmin || this.isSuperAdmin || this.isOperator;
    },
    canManageClient(): boolean {
      return this.isAdmin || this.isSuperAdmin || this.isOperator;
    },
    canEditProfile(): boolean {
      return this.isAdmin || this.isSuperAdmin || this.isOperator;
    },
    canAccessChatFiles(): boolean {
      return this.isAdmin || this.isSuperAdmin || this.isOperator;
    },
    canUploadChatFiles(): boolean {
      return this.isAdmin || this.isSuperAdmin || this.isOperator;
    },
    /**
     * @deprecated Use canCreateMassMessage instead
     */
    CanCreateMassMessage(): boolean {
      return this.canCreateMassMessage;
    },
    canCreateMassMessage(): boolean {
      return this.isAdmin || this.isSuperAdmin || this.isOperator;
    },
    canCreateChat(): boolean {
      return this.isAdmin || this.isSuperAdmin || this.isOperator;
    },
    canIntoHome(): boolean {
      return this.isAdmin || this.isSuperAdmin || this.isOperator;
    },
    canEditNameGroup(): boolean {
      return this.isAdmin || this.isSuperAdmin || this.isOperator;
    },
    canCreateGroup(): boolean {
      return this.isAdmin || this.isSuperAdmin;
    },
    canAddUserToChat(): boolean {
      return this.isAdmin || this.isSuperAdmin || this.isOperator;
    },
    canReviewChatsSettings(): boolean {
      return this.isAdmin || this.isSuperAdmin || this.isOperator;
    },
    canViewUsersOperator(): boolean {
      return this.isOperator;
    },
    canViewUsersAdmin(): boolean {
      return this.isAdmin || this.isSuperAdmin;
    },
  },
});
