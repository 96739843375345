import { fullUrl } from '@/core/httpService.service';
import { isNil, omitBy } from 'lodash-es';
import { BSON } from 'realm-web';
import { defaultedData } from '../../utils/helpers';
import { SchemaBase } from './schema-base';

export interface UsuariosSchema extends SchemaBase {
  photo: string;
  name: string;
  last_name: string;
  email: string;
  online: boolean;
  status: Status;
  rol_user: BSON.ObjectID;
  company?: BSON.ObjectID;
  owner?: BSON.ObjectID;
  owner_email?: string;
  verification?: boolean;
}
export type Status = 'active' | 'inactive';

export interface IUserData {
  id: string;
  companyId: string;
  roleId: string;

  photo: string;
  photoUrl: string;

  phone: string;

  enabled: boolean;

  name: string;
  lastName: string;
  fullName: string;
  email: string;
  online: boolean;
  status: Status;
  verified: boolean;

  ownerId: string | null;

  createdAt: Date | null;
  updatedAt: Date | null;
}

/**
 * Convierte un objeto UsuariosSchema parcial a IUserData normalizado.
 *
 * @param partialData
 * @returns
 */
export const mapUserSchemaToData = (partialData: Partial<UsuariosSchema> | Partial<IUserData>): IUserData => {
  const defaultValue: IUserData = {
    companyId: '',
    roleId: '',
    photo: '',
    photoUrl: '',
    phone: '',
    enabled: false,
    name: '',
    lastName: '',
    fullName: '',
    email: '',
    online: false,
    status: 'active',
    ownerId: null,
    createdAt: null,
    updatedAt: null,
    id: '',
    verified: false,
  };

  const mergedPartialData = partialData as Partial<UsuariosSchema> & Partial<IUserData>;

  const dataFromSchema: Partial<IUserData> = {
    companyId: mergedPartialData.company?.toString() ?? mergedPartialData.companyId,
    roleId: mergedPartialData.rol_user?.toString(),
    photo: mergedPartialData.photo,
    photoUrl: mergedPartialData.photoUrl,
    phone: mergedPartialData.phone,
    enabled: mergedPartialData.enabled,
    name: mergedPartialData.name,
    lastName: mergedPartialData.last_name ?? mergedPartialData.lastName,
    email: mergedPartialData.email,
    online: mergedPartialData.online === true,
    status: mergedPartialData.status,
    createdAt: mergedPartialData.created_at ?? mergedPartialData.createdAt,
    updatedAt: mergedPartialData.updated_at ?? mergedPartialData.updatedAt,
    id: mergedPartialData._id?.toString() ?? mergedPartialData.id,
    ownerId: mergedPartialData.owner?.toString() ?? mergedPartialData.ownerId,
    verified: mergedPartialData.verification ?? mergedPartialData.verified,
  };

  if (!dataFromSchema.photoUrl) {
    dataFromSchema.photoUrl = fullUrl(dataFromSchema.photo);
  }

  const data = defaultedData(omitBy(dataFromSchema, isNil), defaultValue);

  if (!data.fullName) {
    data.fullName = `${data.name ?? ''} ${data.lastName ?? ''}`.trim();
  }

  return data;
};
