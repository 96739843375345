import { defineStore } from 'pinia';

export const useLoaderStore = defineStore('loader', {
  state: () => ({
    loading: false,
  }),
  actions: {
    setLoader(value: boolean) {
      this.loading = value;
      if (value) {
        setTimeout(() => {
          this.loading = false;
        }, 6000);
      }
    },
  },
});
