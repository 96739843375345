import { realmService } from '@/services/realm.service';
import { defineStore } from 'pinia';
import { BSON } from 'realm-web';
import { IRoleData } from '../models/schema/role.data';

export const useRolesStore = defineStore('roles', {
  state: () => ({
    itemsMap: {} as Record<string, IRoleData>, // Almacena los roles indexados por su ID
  }),

  actions: {
    async loadRoles(options: { force?: boolean } = {}): Promise<IRoleData[]> {
      if (!options.force && Object.keys(this.itemsMap).length > 0) {
        return Object.values(this.itemsMap);
      }

      try {
        // Cargar todos los roles desde la base de datos
        const roles = await realmService.getInstance().getRoles();

        // Mapear cada rol al formato IRoleData y almacenarlo en itemsMap
        const rolesData = roles.map((role) => {
          const roleData = IRoleData.normalizedFrom(role);

          this.itemsMap[roleData.id] = roleData;

          return roleData;
        });

        return rolesData;
      } catch (error) {
        console.error('Error al obtener los roles:', error);

        throw error;
      }
    },

    async fetchRoleById(roleId: string | BSON.ObjectID): Promise<IRoleData | null> {
      if (!roleId) {
        return null;
      }

      const roleIdStr = roleId.toString();

      if (!this.itemsMap[roleIdStr]) {
        await this.loadRoles();
      }

      return this.itemsMap[roleIdStr] ?? null;
    },
  },
});
