import { TrackJS } from 'trackjs';

// Antes de importar este archivo se debe comprobar

// Evitar que se instale Track.js en el entorno de desarrollo

if (!import.meta.env.DEV) {
  TrackJS.install({
    token: import.meta.env.VITE_TRACK_JS_TOKEN,
    application: import.meta.env.VITE_TRACK_JS_APPLICATION,
  });

  TrackJS.addMetadata('environment', import.meta.env.MODE);
}
