import { IconDefinition, config, library } from '@fortawesome/fontawesome-svg-core';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';
// import { fad } from '@fortawesome/pro-duotone-svg-icons';
// import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
// import { fat } from '@fortawesome/pro-thin-svg-icons';
// import { fasl } from '@fortawesome/sharp-light-svg-icons';
// import { fasr } from '@fortawesome/sharp-regular-svg-icons';
// import { fass } from '@fortawesome/sharp-solid-svg-icons';
// import { fast } from '@fortawesome/sharp-thin-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { App } from 'vue';

export const installFontAwesome = (app: App<Element>) => {
  config.styleDefault = 'fa-regular';

  library.add(
    // Solid
    fas,
    // fass,

    // Regular
    far,
    // fasr,

    // Light
    // fal,
    // fasl,

    // Duotone
    // fad,

    // Thin
    // fat,
    // fast

    // Brands
    faApple as IconDefinition,
    faGooglePlay as IconDefinition
  );

  app.component('font-awesome-icon', FontAwesomeIcon);
  app.component('fa', FontAwesomeIcon);
  app.component('fa-layers', FontAwesomeLayers);
  app.component('fa-layers-text', FontAwesomeLayersText);
};
